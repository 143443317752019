import { useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { TranslatorNS } from "../../../../i18n/const";
import { formatDate } from "../../../../helpers/utils";
import { extractTranslatedError } from "../../../../helpers/error";
import { addNewClientTask } from "../../../../store/currentClient/action";
import { AddNewTaskRequest } from "../../../../store/currentClient/types";
import { Tooltip } from "@mui/material";
import Iconify from "../../../../components/_MUI/Iconify";
import { LIST_TITLE_LIMIT } from "../../../../helpers/listHelper";

interface AddTaskFormValues {
  title: string;
  dueDate: Date;
}

const initialFormValues: AddTaskFormValues = {
  title: "",
  dueDate: new Date(),
};

const validationSchema = yup.object({
  title: yup.string().required(),
  dueDate: yup.date().nullable(),
});

function AddTaskModal({
  clientId,
  addNewClientTask,
}: {
  clientId: number;
  addNewClientTask: (data: AddNewTaskRequest) => Promise<void>;
}) {
  const [formError, setFormError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [addTaskModalOpened, setAddTaskModalOpened] = useState<boolean>(false);

  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  const onAddTaskSubmit = async (values: AddTaskFormValues) => {
    try {
      setIsSubmitting(true);

      await addNewClientTask({
        client: clientId,
        title: values.title,
        dueDate: formatDate(values.dueDate) ?? null,
      });

      setAddTaskModalOpened(false);
    } catch (error: any) {
      setFormError(extractTranslatedError(error, t));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Tooltip title={t("clientTask.addTask")}>
        <Stack>
          <Button
            variant="text"
            size={"small"}
            onClick={() => setAddTaskModalOpened(true)}
            disabled>
            {t("clientTask.add")}
            <Iconify icon="uil:lock-alt" fontSize={24} ml={0.5} />
          </Button>
        </Stack>
      </Tooltip>
      <Dialog
        open={addTaskModalOpened}
        onClose={() => setAddTaskModalOpened(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "444px!important",
          },
        }}>
        <DialogTitle>{t("modalAddTask.addNewTask")}</DialogTitle>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={onAddTaskSubmit}>
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Stack direction="column" spacing={2}>
                  <TextField
                    fullWidth
                    name="title"
                    inputProps={{ maxLength: LIST_TITLE_LIMIT }}
                    label={`${t("modalAddTask.tasksTitle")} (${
                      LIST_TITLE_LIMIT - values.title?.length
                    })`}
                    onChange={handleChange}
                    value={values.title}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  <DatePicker
                    label={t("modalAddTask.dueDate")}
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    value={values.dueDate}
                    onChange={val => setFieldValue("dueDate", val, true)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        error={touched.dueDate && Boolean(errors.dueDate)}
                        helperText={touched.dueDate && (errors.dueDate as string)}
                      />
                    )}
                  />
                  {formError && (
                    <Alert
                      severity="error"
                      sx={{ whiteSpace: "pre-line" }}
                      onClose={() => setFormError("")}>
                      {formError}
                    </Alert>
                  )}
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                  sx={{ width: "100%", mt: { xs: 2, sm: 0 } }}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    onClick={() => setAddTaskModalOpened(false)}>
                    {t("modalAddTask.cancel")}
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    fullWidth>
                    {t("modalAddTask.add")}
                  </LoadingButton>
                </Stack>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default connect(null, { addNewClientTask })(AddTaskModal);
