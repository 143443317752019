import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Query = {
  __typename?: "Query";
  apiKeys: ApiKeyTypePagination;
  clientFilterCount?: Maybe<ClientFilterCountType>;
  client: ClientType;
  /** Get a list of clients */
  clients: ClientTypePagination;
  deleteMessage?: Maybe<Scalars["Boolean"]>;
  messages: MessageTypePagination;
  /** A query for getting languages */
  languages: LanguageTypePagination;
  leads: LeadTypePagination;
  mealPlanTemplate: MealPlanType;
  mealPlanTemplates: MealPlanTypePagination;
  getIngredients: IngredientTypePagination;
  recipe: RecipeType;
  recipes: RecipeTypePagination;
  /** Get data about me */
  me: CoachType;
  savedWorkout: SavedWorkoutType;
  savedWorkouts: Array<SavedWorkoutType>;
  getTrackedWorkouts: WorkoutTrackingType;
  workoutPlan: WorkoutPlanType;
  plans: PlanTypePagination;
};

export type QueryApiKeysArgs = {
  pagination?: InputMaybe<PaginationInputType>;
};

export type QueryClientFilterCountArgs = {
  search?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryClientArgs = {
  id: Scalars["Int"];
};

export type QueryClientsArgs = {
  pagination?: InputMaybe<PaginationInputType>;
  orderBy?: InputMaybe<ClientsOrderByInputType>;
  filters?: InputMaybe<ClientFilterInputType>;
};

export type QueryDeleteMessageArgs = {
  id: Scalars["Int"];
};

export type QueryMessagesArgs = {
  clientId: Scalars["Int"];
  filters?: InputMaybe<MessagesFilterInputType>;
  orderBy?: InputMaybe<MessageOrderByInputType>;
  pagination?: InputMaybe<PaginationInputType>;
};

export type QueryLanguagesArgs = {
  pagination?: InputMaybe<PaginationInputType>;
};

export type QueryLeadsArgs = {
  pagination?: InputMaybe<PaginationInputType>;
  orderBy?: InputMaybe<LeadOrderByInputType>;
};

export type QueryMealPlanTemplateArgs = {
  id: Scalars["Int"];
};

export type QueryMealPlanTemplatesArgs = {
  pagination?: InputMaybe<PaginationInputType>;
  orderBy?: InputMaybe<MealPlanTemplatesOrderByInputType>;
  filters?: InputMaybe<MealPlanTemplateFilterInputType>;
};

export type QueryGetIngredientsArgs = {
  pagination?: InputMaybe<PaginationInputType>;
  orderBy?: InputMaybe<IngredientOrderByInputType>;
  filters?: InputMaybe<MealProductFilterInputType>;
};

export type QueryRecipeArgs = {
  id: Scalars["Int"];
  locale?: InputMaybe<Scalars["String"]>;
};

export type QueryRecipesArgs = {
  pagination?: InputMaybe<PaginationInputType>;
  orderBy?: InputMaybe<RecipeOrderByInputType>;
  filters?: InputMaybe<RecipeFiltersInputType>;
};

export type QuerySavedWorkoutArgs = {
  id: Scalars["Int"];
};

export type QuerySavedWorkoutsArgs = {
  clientId: Scalars["Int"];
  period: TimePeriodInputType;
};

export type QueryGetTrackedWorkoutsArgs = {
  workoutDayId: Scalars["Int"];
  date: Scalars["String"];
};

export type QueryWorkoutPlanArgs = {
  id: Scalars["Int"];
};

export type QueryPlansArgs = {
  pagination?: InputMaybe<PaginationInputType>;
  filters?: InputMaybe<PlansFilterInputType>;
};

export type PaginationInputType = {
  /** Page number */
  page?: InputMaybe<Scalars["Int"]>;
  /** The number of results per page */
  limit?: InputMaybe<Scalars["Int"]>;
};

export type ApiKeyTypePagination = {
  __typename?: "ApiKeyTypePagination";
  /** List of items on the current page */
  data: Array<ApiKeyType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type ApiKeyType = {
  __typename?: "ApiKeyType";
  id: Scalars["Int"];
  name: Scalars["String"];
  key: Scalars["String"];
};

export type ClientFilterCountType = {
  __typename?: "ClientFilterCountType";
  active: Scalars["Int"];
  activated: Scalars["Int"];
  createPlans: Scalars["Int"];
  updatePlans: Scalars["Int"];
  missingCheckIn: Scalars["Int"];
  pending: Scalars["Int"];
  checkIn: Scalars["Int"];
  unreadMessage: Scalars["Int"];
  noContact: Scalars["Int"];
  paymentFailed: Scalars["Int"];
  ending: Scalars["Int"];
  dueReminders: Scalars["Int"];
  futureReminders: Scalars["Int"];
  paymentPending: Scalars["Int"];
  invitePending: Scalars["Int"];
  questionnairePending: Scalars["Int"];
  readyForActivation: Scalars["Int"];
  requestedUnsubscribe: Scalars["Int"];
};

/** Client entity type */
export type ClientType = {
  __typename?: "ClientType";
  id: Scalars["Int"];
  active: Scalars["Boolean"];
  activated: Scalars["Boolean"];
  /** Hide/show calories in the app */
  hideCalories: Scalars["Boolean"];
  /** The coach associated with the client */
  coach: CoachType;
  isDemo: Scalars["Boolean"];
  clientFoodPreferences?: Maybe<ClientFoodPreferencesType>;
  /** Whether the client can access the app */
  canAccessApp: Scalars["Boolean"];
  /** Whether the client answered the questionnaire */
  answeredQuestionnaire: Scalars["Boolean"];
  workoutPlans: Array<WorkoutPlanType>;
  mealPlans: Array<MealPlanType>;
  progress: Array<ClientProgressType>;
  payments: Array<ClientPaymentType>;
  /** The day the client checks in */
  checkInDay?: Maybe<Scalars["Int"]>;
  /** The frequency of the day the client checks in */
  checkInFrequency?: Maybe<Scalars["Int"]>;
  /** The push notification of checkin date of the client checks in */
  checkInReminderLastSent?: Maybe<Scalars["String"]>;
  unresolvedTasks: Array<ClientStatusType>;
  status: Array<ClientStatusType>;
  reminders: Array<ClientReminderType>;
  paymentConfiguration?: Maybe<ClientPaymentConfigurationType>;
  /** Client integration */
  integrations?: Maybe<ClientIntegrationType>;
  steps: Array<ClientStepType>;
  macros: Array<ClientMacroType>;
  workouts: Array<ClientWorkoutsType>;
  /** Client habit goals */
  habits: Array<ClientHabitType>;
  /** The activation URL of the client. */
  url: Scalars["String"];
  queue?: Maybe<ClientQueueType>;
  customQuestions?: Maybe<Array<Maybe<CustomQuestionType>>>;
  /** The full name of the client */
  name: Scalars["String"];
  email: Scalars["String"];
  /** Pal is the activity level of the client */
  pal?: Maybe<ClientPalEnum>;
  /** The location of the workout */
  workoutLocation?: Maybe<WorkoutLocationEnum>;
  /** Desc */
  workoutExperience?: Maybe<Scalars["String"]>;
  /** Desc */
  workoutExperienceLevel?: Maybe<WorkoutExperienceLevelEnum>;
  /** Desc */
  workoutPreference?: Maybe<Scalars["String"]>;
  /** Desc */
  motivation?: Maybe<Scalars["String"]>;
  /** The date on which the client starts at their trainer */
  birthday?: Maybe<Scalars["String"]>;
  age?: Maybe<Scalars["Int"]>;
  /** Type of measuring system */
  measuringSystem?: Maybe<MeasuringSystemEnum>;
  /** The number of meals per day */
  numberOfMeals?: Maybe<Scalars["Int"]>;
  workoutsPerWeek?: Maybe<Scalars["Int"]>;
  /** The photo of the client */
  photo?: Maybe<Scalars["String"]>;
  /** The phone number of the client */
  phone?: Maybe<Scalars["String"]>;
  /** The height in centimeters */
  height?: Maybe<Scalars["Float"]>;
  /** The height in feet */
  feet?: Maybe<Scalars["Float"]>;
  /** The height in inches */
  inches?: Maybe<Scalars["Float"]>;
  /** Lifestyle habits */
  lifestyle?: Maybe<Scalars["String"]>;
  /** The clients weight goal */
  weightGoal?: Maybe<Scalars["Float"]>;
  /** A note about the clients diet style */
  dietStyle?: Maybe<Scalars["String"]>;
  gender?: Maybe<GenderEnum>;
  locale?: Maybe<LocaleEnum>;
  /** Injuries text */
  injuries?: Maybe<Scalars["String"]>;
  /** Desc */
  other?: Maybe<Scalars["String"]>;
  /** The start weight in kilos as a float */
  startWeight?: Maybe<Scalars["Float"]>;
  /** The date on which the client starts at their trainer */
  startDate?: Maybe<Scalars["String"]>;
  /** The date on which the client starts at their trainer */
  endDate?: Maybe<Scalars["String"]>;
  /** Determine how often the meal plans should be updated */
  mealPlanSchedule?: Maybe<ClientPlansScheduleEnum>;
  /** Determine how often the workout plans should be updated */
  workoutPlanSchedule?: Maybe<ClientPlansScheduleEnum>;
  /** The personal note of the trainer related to the client */
  note?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  unreadMessagesCount?: Maybe<Scalars["Int"]>;
  /** The date on which the client last checked-in */
  lastCheckIn?: Maybe<Scalars["String"]>;
  weekNumber?: Maybe<Scalars["Int"]>;
  totalWeeks?: Maybe<Scalars["Int"]>;
  hasCreatedPassword: Scalars["Boolean"];
  token?: Maybe<Scalars["String"]>;
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
};

/** Client entity type */
export type ClientTypeCheckInReminderLastSentArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** Client entity type */
export type ClientTypeBirthdayArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** Client entity type */
export type ClientTypeStartDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** Client entity type */
export type ClientTypeEndDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** Client entity type */
export type ClientTypeLastCheckInArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type CoachType = {
  __typename?: "CoachType";
  /** The ID of the coach */
  id: Scalars["Int"];
  /** The name of the coach */
  name: Scalars["String"];
  /** The first name of the coach */
  firstName?: Maybe<Scalars["String"]>;
  /** The last name of the coach */
  lastName?: Maybe<Scalars["String"]>;
  /** The phone number of the coach */
  phone?: Maybe<Scalars["String"]>;
  /** The country */
  country?: Maybe<Scalars["String"]>;
  /** The number of active clients */
  activeClientCount: Scalars["Int"];
  /** The number of inactive clients */
  inactiveClientCount: Scalars["Int"];
  /** The number of pending clients */
  pendingClientCount: Scalars["Int"];
  /** The email of the coach */
  email: Scalars["String"];
  /** If the coach is active */
  isActive: Scalars["Boolean"];
  /** If the user is an admin */
  isAdmin: Scalars["Boolean"];
  /** The Zenfit subscription the coach has */
  subscription?: Maybe<CoachSubscriptionType>;
  /** If user is connected to Stripe Connect */
  isConnectedToStripeConnect: Scalars["Boolean"];
  /** The date the coach signed up */
  signupDate?: Maybe<Scalars["String"]>;
  /** The locale of the coach */
  language?: Maybe<LanguageType>;
  /** The login token */
  token?: Maybe<Scalars["String"]>;
  /** The token used for Zenfit Forms to send data to new leads */
  interactiveToken?: Maybe<Scalars["String"]>;
  /** The type */
  type?: Maybe<UserTypeEnum>;
  /** Settings for user */
  settings?: Maybe<UserSettingsType>;
  stripeConnectUrl?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  customQuestions?: Maybe<Array<Maybe<CustomQuestionType>>>;
  /** If the coach can create clients */
  disableClientCreation: Scalars["Boolean"];
};

/** The subscription a coach pays to Zenfit */
export type CoachSubscriptionType = {
  __typename?: "CoachSubscriptionType";
  /** The ID of the subscription */
  id: Scalars["Int"];
  /** The Stripe customer identifier */
  stripeCustomerNumber?: Maybe<Scalars["String"]>;
  lastPaymentFailed?: Maybe<Scalars["Boolean"]>;
  invoiceUrl?: Maybe<Scalars["String"]>;
  /** The Stripe subscription identifier */
  stripeSubscriptionId?: Maybe<Scalars["String"]>;
  /** The Plan */
  plan?: Maybe<PlanType>;
  /** The date */
  subscribedAt?: Maybe<Scalars["String"]>;
  /** The date */
  unsubscribedAt?: Maybe<Scalars["String"]>;
  /** The date the trial ends */
  trialEndDate?: Maybe<Scalars["String"]>;
  /** If Coach has a commitment period */
  committedUntil?: Maybe<Scalars["String"]>;
};

/** The subscription a coach pays to Zenfit */
export type CoachSubscriptionTypeCommittedUntilArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type PlanType = {
  __typename?: "PlanType";
  /** The id of the plan */
  id: Scalars["Int"];
  /** The name of the plan */
  name: Scalars["String"];
  /** The email of the lead */
  currency: Scalars["String"];
  /** The phone of the lead */
  country: Scalars["String"];
  /** The active status of the plan */
  active: Scalars["Boolean"];
  /** The number of clients on the plan */
  clientCount: Scalars["Int"];
  /** Whether this subscription is per client or not */
  isTiered?: Maybe<Scalars["Boolean"]>;
  /** The monthly price for this subscription. */
  priceMonth: Scalars["Int"];
};

export type LanguageType = {
  __typename?: "LanguageType";
  id: Scalars["Int"];
  /** The name of the language */
  name: Scalars["String"];
  /** The Locale */
  locale: Scalars["String"];
};

export enum UserTypeEnum {
  Coach = "Coach",
  Assistant = "Assistant",
}

export type UserSettingsType = {
  __typename?: "UserSettingsType";
  id: Scalars["Int"];
  /** The name of the coach */
  companyName?: Maybe<Scalars["String"]>;
  /** The logo */
  companyLogo?: Maybe<Scalars["String"]>;
  /** The number of clients */
  useLenusRecipes: Scalars["Boolean"];
  clientFilters?: Maybe<Scalars["String"]>;
  checkinQuestions?: Maybe<Scalars["String"]>;
  isExperimental?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<VersionEnum>;
  profilePicture?: Maybe<Scalars["String"]>;
  platformOrigin: Scalars["String"];
  /** The start date of the coach */
  startDate?: Maybe<Scalars["String"]>;
  transferInProgress: TransferProgressEnum;
  salesforceId?: Maybe<Scalars["String"]>;
  useLenusExercises: Scalars["Boolean"];
};

export type UserSettingsTypeStartDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export enum VersionEnum {
  Legacy = "LEGACY",
  Stable = "STABLE",
  Experimental = "EXPERIMENTAL",
}

export enum TransferProgressEnum {
  NotStarted = "NOT_STARTED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
}

export type CustomQuestionType = {
  __typename?: "CustomQuestionType";
  id?: Maybe<Scalars["Int"]>;
  question: Scalars["String"];
  answer?: Maybe<Scalars["String"]>;
};

export type ClientFoodPreferencesType = {
  __typename?: "ClientFoodPreferencesType";
  /** If client does not want lactose */
  lactose: Scalars["Boolean"];
  /** If client does not want gluten */
  gluten: Scalars["Boolean"];
  /** If client does not want nuts */
  nuts: Scalars["Boolean"];
  /** If client does not want eggs */
  eggs: Scalars["Boolean"];
  /** If client does not want pig */
  pig: Scalars["Boolean"];
  /** If client does not want shellfish */
  shellfish: Scalars["Boolean"];
  /** If client does not want fish */
  fish: Scalars["Boolean"];
  /** If client is vegetarian */
  isVegetarian: Scalars["Boolean"];
  /** If client is vegan */
  isVegan: Scalars["Boolean"];
  /** If client is pescetarian */
  isPescetarian: Scalars["Boolean"];
  /** If client has eating disorder */
  uncomfortablyFull: Scalars["Boolean"];
  /** If client has eating disorder */
  lostControl: Scalars["Boolean"];
  /** If client has eating disorder */
  significantLoss: Scalars["Boolean"];
  /** If client has eating disorder */
  feelsFat: Scalars["Boolean"];
  /** If client has eating disorder */
  foodDominates: Scalars["Boolean"];
};

export type WorkoutPlanType = {
  __typename?: "WorkoutPlanType";
  id: Scalars["Int"];
  /** The title of the plan */
  name: Scalars["String"];
  /** The trainer's own internal comment (not visible for the client) */
  description?: Maybe<Scalars["String"]>;
  /** The description of the plan (visible to the client) */
  comment?: Maybe<Scalars["String"]>;
  /** The status of the plan */
  status: Scalars["String"];
  /** The list of workout days */
  days?: Maybe<Array<Maybe<WorkoutDayType>>>;
  meta?: Maybe<WorkoutPlanMetaType>;
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
};

export type WorkoutDayType = {
  __typename?: "WorkoutDayType";
  id: Scalars["Int"];
  /** The title of the day */
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  /** The exercises */
  exercises: Array<Maybe<WorkoutType>>;
  workoutPlan?: Maybe<WorkoutPlanType>;
};

export type WorkoutType = {
  __typename?: "WorkoutType";
  id: Scalars["Int"];
  /** The title of the workout */
  title?: Maybe<Scalars["String"]>;
  /** List of workout set configurations */
  workoutSets?: Maybe<Array<Maybe<WorkoutSetType>>>;
  /** The reps of the workout */
  reps?: Maybe<Scalars["String"]>;
  /** The sets of the workout */
  sets?: Maybe<Scalars["String"]>;
  /** The rest of the workout */
  rest?: Maybe<Scalars["String"]>;
  /** The time of the workout */
  time?: Maybe<Scalars["String"]>;
  /** The children of the workout */
  children?: Maybe<Array<Maybe<WorkoutType>>>;
  /** This order. This order. This oooooooorder!!!! (System of a Dawn) */
  order?: Maybe<Scalars["Int"]>;
  /** id of the parent */
  parentId?: Maybe<Scalars["Int"]>;
  /** The exercise */
  exercise?: Maybe<ExerciseType>;
  /** The muscle group */
  muscleGroup?: Maybe<MuscleGroupType>;
  description?: Maybe<Scalars["String"]>;
  tracking: Array<WorkoutTrackingType>;
};

export type WorkoutSetType = {
  __typename?: "WorkoutSetType";
  id: Scalars["Int"];
  /** The reps of the set */
  reps?: Maybe<Scalars["String"]>;
  /** The rest of the set */
  rest?: Maybe<Scalars["String"]>;
  orderBy: Scalars["Int"];
};

export type ExerciseType = {
  __typename?: "ExerciseType";
  id: Scalars["Int"];
  /** The title of the exercise */
  title: Scalars["String"];
  /** The image of the exercise */
  image?: Maybe<Scalars["String"]>;
  /** The video of the exercise */
  video?: Maybe<Scalars["String"]>;
  type?: Maybe<ExerciseTypeEnum>;
  isLenusExercise: Scalars["Boolean"];
  lenusId?: Maybe<Scalars["String"]>;
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
  lenusUuid?: Maybe<Scalars["String"]>;
  execution?: Maybe<Scalars["String"]>;
  muscleGroupId?: Maybe<Scalars["Int"]>;
  equipmentId?: Maybe<Scalars["Int"]>;
  exerciseTypeId?: Maybe<Scalars["Int"]>;
};

export enum ExerciseTypeEnum {
  /** Exercise */
  Reps = "REPS",
  /** Superset */
  Time = "TIME",
}

export type MuscleGroupType = {
  __typename?: "MuscleGroupType";
  id: Scalars["Int"];
  /** The name of the muscle group */
  name: Scalars["String"];
};

export type WorkoutTrackingType = {
  __typename?: "WorkoutTrackingType";
  id: Scalars["Int"];
  reps?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  /** Date field */
  date: Scalars["String"];
  time?: Maybe<Scalars["String"]>;
  workout?: Maybe<WorkoutType>;
};

export type WorkoutTrackingTypeDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type WorkoutPlanMetaType = {
  __typename?: "WorkoutPlanMetaType";
  level?: Maybe<WorkoutExperienceLevelEnum>;
  location?: Maybe<WorkoutLocationEnum>;
  workoutsPerWeek?: Maybe<Scalars["Int"]>;
  gender?: Maybe<GenderEnum>;
  id: Scalars["Int"];
};

export enum WorkoutExperienceLevelEnum {
  /** None */
  None = "NONE",
  /** beginner and have little to no experience */
  Beginner = "BEGINNER",
  /** trained for some time and know the fundamentals */
  Intermediate = "INTERMEDIATE",
  /** experienced and have trained for years */
  Experienced = "EXPERIENCED",
}

export enum WorkoutLocationEnum {
  None = "NONE",
  Gym = "GYM",
  Home = "HOME",
  Outdoor = "OUTDOOR",
}

export enum GenderEnum {
  None = "NONE",
  Male = "MALE",
  Female = "FEMALE",
}

export type MealPlanType = {
  __typename?: "MealPlanType";
  id: Scalars["Int"];
  desiredKcals?: Maybe<Scalars["Int"]>;
  /** The title of the plan */
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  /** The list of meal types */
  mealTypes: Array<MealTypeType>;
  /** The totals in meal plans */
  totals?: Maybe<MealPlanTotalsType>;
  macroSplit?: Maybe<LenusMacroSplitEnum>;
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
};

export type MealTypeType = {
  __typename?: "MealTypeType";
  id: Scalars["Int"];
  /** The name of the meal */
  title: Scalars["String"];
  /** The type of the meal */
  type?: Maybe<MealTypeEnum>;
  /** The children of the meal */
  meals: Array<MealType>;
};

export enum MealTypeEnum {
  Breakfast = "BREAKFAST",
  Lunch = "LUNCH",
  Dinner = "DINNER",
  MorningSnack = "MORNING_SNACK",
  AfternoonSnack = "AFTERNOON_SNACK",
  EveningSnack = "EVENING_SNACK",
  Other = "OTHER",
}

export type MealType = {
  __typename?: "MealType";
  id: Scalars["Int"];
  order?: Maybe<Scalars["Int"]>;
  /** The name of the meal */
  title: Scalars["String"];
  /** The type of the meal */
  type?: Maybe<MealTypeEnum>;
  ingredients: Array<MealIngredientType>;
};

export type MealIngredientType = {
  __typename?: "MealIngredientType";
  id: Scalars["Int"];
  order?: Maybe<Scalars["Int"]>;
  amount?: Maybe<Scalars["Int"]>;
  ingredient?: Maybe<IngredientType>;
  unit?: Maybe<MealIngredientUnitType>;
  unitAmount?: Maybe<Scalars["Float"]>;
};

export type IngredientType = {
  __typename?: "IngredientType";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  kcal?: Maybe<Scalars["Int"]>;
  lenusId?: Maybe<Scalars["String"]>;
  units: Array<MealIngredientUnitType>;
  carbohydrates?: Maybe<Scalars["Float"]>;
  fat?: Maybe<Scalars["Float"]>;
  protein?: Maybe<Scalars["Float"]>;
  lenusMigratedOwnedId?: Maybe<Scalars["String"]>;
  lenusUuid?: Maybe<Scalars["String"]>;
};

export type MealIngredientUnitType = {
  __typename?: "MealIngredientUnitType";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Float"]>;
};

export type MealPlanTotalsType = {
  __typename?: "MealPlanTotalsType";
  /** The number of meals in the meal plan */
  numberOfMeals?: Maybe<Scalars["Int"]>;
  /** The kcals in the meal */
  kcal?: Maybe<Scalars["Float"]>;
  /** The protein in the meal */
  protein?: Maybe<Scalars["Float"]>;
  /** The carbs in the meal */
  carbs?: Maybe<Scalars["Float"]>;
  /** The fat in the meal */
  fat?: Maybe<Scalars["Float"]>;
};

export enum LenusMacroSplitEnum {
  P30C40F10 = "P30_C40_F10",
  P40C40F20 = "P40_C40_F20",
  P30C50F20 = "P30_C50_F20",
  P30C30F40 = "P30_C30_F40",
  P20C50F30 = "P20_C50_F30",
  P40C30F30 = "P40_C30_F30",
  P50C10F40 = "P50_C10_F40",
  P30C20F50 = "P30_C20_F50",
  P30C10F60 = "P30_C10_F60",
  P40C50F10 = "P40_C50_F10",
  P10C60F30 = "P10_C60_F30",
}

export type ClientProgressType = {
  __typename?: "ClientProgressType";
  id: Scalars["Int"];
  weight?: Maybe<Scalars["Float"]>;
  /** Date field */
  date?: Maybe<Scalars["String"]>;
};

export type ClientProgressTypeDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientPaymentType = {
  __typename?: "ClientPaymentType";
  id: Scalars["Int"];
  /** The type of the payment */
  type: PaymentTypeEnum;
  /** The amount of the payment */
  amount?: Maybe<Scalars["Float"]>;
  /** The failure reason for the payment */
  reason?: Maybe<Scalars["String"]>;
  /** The Stripe Charge ID of the payment. */
  chargeId?: Maybe<Scalars["String"]>;
  currency?: Maybe<CurrencyEnum>;
  /** The date of the payment */
  date?: Maybe<Scalars["String"]>;
};

export type ClientPaymentTypeDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** This is the type of the payment. Klarna, SEPA, and Card should be considered as same type */
export enum PaymentTypeEnum {
  /** Card & SEPA: The payment has failed */
  PaymentFailed = "PAYMENT_FAILED",
  /** Card & SEPA: The payment has succeeded */
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  /** Klarna: The payment has succeeded */
  ChargeSucceeded = "CHARGE_SUCCEEDED",
  /** Card, SEPA and Klarna: The payment was refunded */
  ChargeRefunded = "CHARGE_REFUNDED",
  /** The subscription was canceled */
  SubscriptionCanceled = "SUBSCRIPTION_CANCELED",
  /**
   * The subscription has been created
   * @deprecated The field is no longer in use
   */
  SubscriptionPaymentCreated = "SUBSCRIPTION_PAYMENT_CREATED",
  /** The subscription's first payment is pending */
  SubscriptionPaymentWaiting = "SUBSCRIPTION_PAYMENT_WAITING",
  /** The charge was disputed */
  ChargeDispute = "CHARGE_DISPUTE",
}

export enum CurrencyEnum {
  /** DKK */
  Dkk = "dkk",
  /** SEK */
  Sek = "sek",
  /** NOK */
  Nok = "nok",
  /** EUR */
  Eur = "eur",
  /** USD */
  Usd = "usd",
  /** GBP */
  Gbp = "gbp",
  /** CHF */
  Chf = "chf",
}

export type ClientStatusType = {
  __typename?: "ClientStatusType";
  id: Scalars["Int"];
  /** The date */
  date?: Maybe<Scalars["String"]>;
  /** The event */
  event: EventType;
  /** Resolved: yes / no */
  resolved: Scalars["Boolean"];
  /** The date the status was resolved */
  resolvedBy?: Maybe<Scalars["String"]>;
};

export type ClientStatusTypeDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientStatusTypeResolvedByArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type EventType = {
  __typename?: "EventType";
  id: Scalars["Int"];
  /** The event code name */
  name: EventNameEnum;
  /** The event title */
  title: Scalars["String"];
};

export enum EventNameEnum {
  /** Client needs a welcome message */
  Activated = "ACTIVATED",
  /** The client has not completed the questionnaire */
  QuestionnairePending = "QUESTIONNAIRE_PENDING",
  /** The client has not created an account */
  InvitePending = "INVITE_PENDING",
  /** The client has not checked in for their progress */
  MissingCheckIn = "MISSING_CHECK_IN",
  /** The client has checked in for their progress */
  CheckIn = "CHECK_IN",
  /** The client has uploaded an image */
  UploadedImage = "UPLOADED_IMAGE",
  /** The client has an incorrect email address */
  WrongEmail = "WRONG_EMAIL",
  /** The client has updated their macros */
  UpdatedMacros = "UPDATED_MACROS",
  /** The client needs plans */
  NeedPlans = "NEED_PLANS",
  /** The trainer has unread messages */
  UnansweredMessage = "UNANSWERED_MESSAGE",
  /** The client has not communicated with the trainer */
  NoContact = "NO_CONTACT",
  /** The client has not completed the payment */
  PaymentPending = "PAYMENT_PENDING",
  /** The client payment failed */
  PaymentFailed = "PAYMENT_FAILED",
  /** The client subscription has ended */
  SubscriptionCanceled = "SUBSCRIPTION_CANCELED",
  /** The client duration is ending soon */
  EndingSoon = "ENDING_SOON",
  /** The client duration has ended */
  Completed = "COMPLETED",
  /** The trainer should created a workout plan */
  CreateWorkoutPlan = "CREATE_WORKOUT_PLAN",
  /** The trainer should updated a workout plan */
  UpdateWorkoutPlan = "UPDATE_WORKOUT_PLAN",
  /** The trainer should create a meal plan */
  CreateMealPlan = "CREATE_MEAL_PLAN",
  /** The trainer should update meal plan */
  UpdateMealPlan = "UPDATE_MEAL_PLAN",
  /** The trainer has activated a client */
  TrainerActivatedClient = "TRAINER_ACTIVATED_CLIENT",
  /** The client has answered the questionnaire */
  AnsweredQuestionnaire = "ANSWERED_QUESTIONNAIRE",
  /** The client has created a login */
  CreatedLogin = "CREATED_LOGIN",
  FilledOutSurvey = "FILLED_OUT_SURVEY",
  ClientLogin = "CLIENT_LOGIN",
  NoBodyprogress = "NO_BODYPROGRESS",
  NoLogin = "NO_LOGIN",
  RemindersUnresolved = "REMINDERS_UNRESOLVED",
  RequestUnsubscribed = "REQUEST_UNSUBSCRIBED",
  RequiresInvitation = "REQUIRES_INVITATION",
  RequiresLogin = "REQUIRES_LOGIN",
  UpToDate = "UP_TO_DATE",
  TrainerLogin = "TRAINER_LOGIN",
  SentPushMsg = "SENT_PUSH_MSG",
}

export type ClientReminderType = {
  __typename?: "ClientReminderType";
  id: Scalars["Int"];
  dueDate: Scalars["String"];
  title: Scalars["String"];
  /** Resolved: yes / no */
  resolved: Scalars["Boolean"];
};

export type ClientPaymentConfigurationType = {
  __typename?: "ClientPaymentConfigurationType";
  id: Scalars["Int"];
  /** The date the subscription was created */
  createdAt?: Maybe<Scalars["String"]>;
  /** The date that the payment subscription ends */
  endDate?: Maybe<Scalars["String"]>;
  currency: CurrencyEnum;
  /** The period of the subscription */
  months: Scalars["Int"];
  /** The monthly subscription amount. */
  recurringFee: Scalars["Float"];
  upfrontFee: Scalars["Float"];
  /** The terms of the subscription. */
  terms?: Maybe<Scalars["String"]>;
  /** If the subscription has started or not. */
  isCharged: Scalars["Boolean"];
  /** The identifier of the payment. */
  datakey: Scalars["String"];
  /** The status of the subscription */
  status: ClientPaymentConfigurationStatusEnum;
  /** The status of the payment. */
  subscription?: Maybe<ClientStripeType>;
  /** The checkout URL of the payment. */
  url: Scalars["String"];
};

export type ClientPaymentConfigurationTypeCreatedAtArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientPaymentConfigurationTypeEndDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** This is the type of the payment. Klarna, SEPA, and Card should be considered as same type */
export enum ClientPaymentConfigurationStatusEnum {
  /** The payment configuration for the client is active */
  Active = "ACTIVE",
  /** The payment configuration for the client has been canceled */
  Canceled = "CANCELED",
  /** The payment configuration for the client has been paused */
  Paused = "PAUSED",
  /** The payment configuration for the client will start */
  WillStart = "WILL_START",
  /** The payment configuration for the client is pending */
  Pending = "PENDING",
  /** The last payment failed */
  Failed = "FAILED",
}

export type ClientStripeType = {
  __typename?: "ClientStripeType";
  /** The ID of the entry */
  id: Scalars["Int"];
  /** The stripe customer */
  stripeCustomer?: Maybe<Scalars["String"]>;
  /** The stripe subscription */
  stripeSubscription?: Maybe<Scalars["String"]>;
  isCanceled: Scalars["Boolean"];
  /** If sub is paused or not */
  isPaused: Scalars["Boolean"];
  /** Sub is paused until date. Is considered a varchar */
  pausedUntil?: Maybe<Scalars["String"]>;
  /** Date which client subscribed */
  subscribedDate?: Maybe<Scalars["String"]>;
  /** Date which client unsubscribed */
  unsubscribedDate?: Maybe<Scalars["String"]>;
  /** Date field */
  upcomingInvoiceDueDate?: Maybe<Scalars["String"]>;
  /** The link to the client's Stripe invoice */
  invoiceUrl?: Maybe<Scalars["String"]>;
};

export type ClientStripeTypeSubscribedDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientStripeTypeUnsubscribedDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientStripeTypeUpcomingInvoiceDueDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientIntegrationType = {
  __typename?: "ClientIntegrationType";
  /** If client is connected to Apple or Google Health */
  healthConnected: Scalars["Boolean"];
  /** If client is connected to MyFitnessPal */
  mfpConnected: Scalars["Boolean"];
};

export type ClientStepType = {
  __typename?: "ClientStepType";
  id: Scalars["Int"];
  steps: Scalars["Int"];
  date: Scalars["String"];
};

export type ClientMacroType = {
  __typename?: "ClientMacroType";
  id: Scalars["Int"];
  kcal: Scalars["Int"];
  carbs: Scalars["Int"];
  protein: Scalars["Int"];
  fat: Scalars["Int"];
  date: Scalars["String"];
};

export type ClientWorkoutsType = {
  __typename?: "ClientWorkoutsType";
  id: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  date: Scalars["String"];
  time: Scalars["Float"];
  day: WorkoutDayType;
};

export type ClientHabitType = {
  __typename?: "ClientHabitType";
  id: Scalars["Int"];
  type?: Maybe<ClientHabitEnum>;
  /** The goal of the habit */
  goal?: Maybe<Scalars["Int"]>;
};

export enum ClientHabitEnum {
  Steps = "STEPS",
  Sleep = "SLEEP",
  Water = "WATER",
}

export type ClientQueueType = {
  __typename?: "ClientQueueType";
  id: Scalars["Int"];
  datakey?: Maybe<Scalars["String"]>;
  /** The date the email was sent */
  date?: Maybe<Scalars["String"]>;
};

export type ClientQueueTypeDateArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

/** The types of pal/activity a client can have  */
export enum ClientPalEnum {
  /** Client don't do any physical exercise */
  None = "NONE",
  /** Client is not very active and work out 2-3 days a week */
  Low = "LOW",
  /** Client is moderately active and work out 3-4 times a week */
  Moderate = "MODERATE",
  /** Client is pretty active and work out 5-6 times a week */
  High = "HIGH",
  /** Client is a beast and have a hard physical job and work out most days */
  Extreme = "EXTREME",
}

export enum MeasuringSystemEnum {
  Metric = "METRIC",
  Imperial = "IMPERIAL",
}

export enum LocaleEnum {
  /** da_DK */
  DaDk = "da_DK",
  /** sv_SE */
  SvSe = "sv_SE",
  /** nb_NO */
  NbNo = "nb_NO",
  /** de_DE */
  DeDe = "de_DE",
  /** fi_FI */
  FiFi = "fi_FI",
  /** nl_NL */
  NlNl = "nl_NL",
  /** en_US */
  EnUs = "en_US",
  /** en_GB */
  EnGb = "en_GB",
  /** en_ES */
  EsEs = "es_ES",
}

/** Enum for meal plans and workout plans update schedule */
export enum ClientPlansScheduleEnum {
  NoUpdate = "NO_UPDATE",
  Every_2Weeks = "EVERY_2_WEEKS",
  Every_4Weeks = "EVERY_4_WEEKS",
  Every_6Weeks = "EVERY_6_WEEKS",
  Every_8Weeks = "EVERY_8_WEEKS",
  Every_12Weeks = "EVERY_12_WEEKS",
  Every_16Weeks = "EVERY_16_WEEKS",
}

export type ClientsOrderByInputType = {
  /** The field you want to sort by */
  field?: InputMaybe<ClientOrderByEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

/** The fields you can order clients by */
export enum ClientOrderByEnum {
  Id = "id",
  Name = "name",
  Email = "email",
  StartDate = "startDate",
  LastCheckIn = "lastCheckIn",
}

/** Sorting direction enum */
export enum SortingDirectionEnum {
  /** Sort ascending */
  Asc = "ASC",
  /** Sort descending */
  Desc = "DESC",
}

export type ClientFilterInputType = {
  search?: InputMaybe<Scalars["String"]>;
  pending?: InputMaybe<Scalars["Boolean"]>;
  active?: InputMaybe<Scalars["Boolean"]>;
  activated?: InputMaybe<Scalars["Boolean"]>;
  createdBetween?: InputMaybe<TimePeriodInputType>;
  createPlans?: InputMaybe<Scalars["Boolean"]>;
  updatePlans?: InputMaybe<Scalars["Boolean"]>;
  missingCheckIn?: InputMaybe<Scalars["Boolean"]>;
  checkIn?: InputMaybe<Scalars["Boolean"]>;
  unreadMessage?: InputMaybe<Scalars["Boolean"]>;
  noContact?: InputMaybe<Scalars["Boolean"]>;
  paymentFailed?: InputMaybe<Scalars["Boolean"]>;
  ending?: InputMaybe<Scalars["Boolean"]>;
  dueReminders?: InputMaybe<Scalars["Boolean"]>;
  futureReminders?: InputMaybe<Scalars["Boolean"]>;
  paymentPending?: InputMaybe<Scalars["Boolean"]>;
  invitePending?: InputMaybe<Scalars["Boolean"]>;
  questionnairePending?: InputMaybe<Scalars["Boolean"]>;
  readyForActivation?: InputMaybe<Scalars["Boolean"]>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  requestedUnsubscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type TimePeriodInputType = {
  /** From */
  from?: InputMaybe<Scalars["String"]>;
  /** To */
  to?: InputMaybe<Scalars["String"]>;
};

export type ClientTypePagination = {
  __typename?: "ClientTypePagination";
  /** List of items on the current page */
  data: Array<ClientType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type MessagesFilterInputType = {
  statuses?: InputMaybe<Array<MessageStatusEnum>>;
  senders?: InputMaybe<Array<MessageSenderEnum>>;
};

export enum MessageStatusEnum {
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Read = "READ",
  Pending = "PENDING",
}

export enum MessageSenderEnum {
  Coach = "COACH",
  Client = "CLIENT",
  System = "SYSTEM",
}

export type MessageOrderByInputType = {
  /** The field you want to order by */
  field?: InputMaybe<MessageOrderByFieldEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

export enum MessageOrderByFieldEnum {
  Id = "ID",
  CreatedAt = "CREATED_AT",
}

export type MessageTypePagination = {
  __typename?: "MessageTypePagination";
  /** List of items on the current page */
  data: Array<MessageType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type MessageType = {
  __typename?: "MessageType";
  sender?: Maybe<MessageSenderEnum>;
  user?: Maybe<CoachType>;
  client?: Maybe<ClientType>;
  content?: Maybe<Scalars["String"]>;
  /** Date field */
  createdAt?: Maybe<Scalars["String"]>;
  status?: Maybe<MessageStatusEnum>;
  media?: Maybe<MediaType>;
  id: Scalars["Int"];
};

export type MessageTypeCreatedAtArgs = {
  format?: InputMaybe<Scalars["String"]>;
  relative?: InputMaybe<Scalars["Boolean"]>;
};

export type MediaType = {
  __typename?: "MediaType";
  type: FileTypeEnum;
  link: Scalars["String"];
};

export enum FileTypeEnum {
  Video = "VIDEO",
  Audio = "AUDIO",
  Pdf = "PDF",
  Image = "IMAGE",
}

export type LanguageTypePagination = {
  __typename?: "LanguageTypePagination";
  /** List of items on the current page */
  data: Array<LanguageType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type LeadOrderByInputType = {
  /** The field you want to order by */
  field?: InputMaybe<UserOrderByFieldEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

export enum UserOrderByFieldEnum {
  Id = "id",
  Name = "name",
  Email = "email",
}

export type LeadTypePagination = {
  __typename?: "LeadTypePagination";
  /** List of items on the current page */
  data: Array<LeadType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type LeadType = {
  __typename?: "LeadType";
  /** The id of the lead */
  id?: Maybe<Scalars["Int"]>;
  /** The name of the lead */
  name?: Maybe<Scalars["String"]>;
  /** The email of the lead */
  email?: Maybe<Scalars["String"]>;
  /** The phone of the lead */
  phone?: Maybe<Scalars["String"]>;
  /** JSON string with questionnaire response */
  response?: Maybe<Scalars["String"]>;
  /** The uuid of the lead in Lenus */
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
};

export type MealPlanTemplatesOrderByInputType = {
  /** The field you want to order by */
  field?: InputMaybe<MealPlanTemplateOrderByFieldEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

export enum MealPlanTemplateOrderByFieldEnum {
  Id = "ID",
  Name = "NAME",
  CreatedAt = "CREATED_AT",
}

export type MealPlanTemplateFilterInputType = {
  search?: InputMaybe<Scalars["String"]>;
  locales?: InputMaybe<Array<LocaleEnum>>;
};

export type MealPlanTypePagination = {
  __typename?: "MealPlanTypePagination";
  /** List of items on the current page */
  data: Array<MealPlanType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type IngredientOrderByInputType = {
  /** The field you want to order by */
  field?: InputMaybe<IngredientOrderByFieldEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

export enum IngredientOrderByFieldEnum {
  Id = "id",
  Brand = "brand",
  Kcal = "kcal",
  Protein = "protein",
}

export type MealProductFilterInputType = {
  search?: InputMaybe<Scalars["String"]>;
  onlyMine?: InputMaybe<Scalars["Boolean"]>;
  locale: LocaleEnum;
};

export type IngredientTypePagination = {
  __typename?: "IngredientTypePagination";
  /** List of items on the current page */
  data: Array<IngredientType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type RecipeType = {
  __typename?: "RecipeType";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  locale?: Maybe<LocaleEnum>;
  cookingTime?: Maybe<CookingTimeEnum>;
  image?: Maybe<Scalars["String"]>;
  servings?: Maybe<Scalars["Int"]>;
  lenusMacroSplit?: Maybe<LenusMacroSplitEnum>;
  zenfitMacroSplit?: Maybe<ZenfitMacroSplitEnum>;
  ingredients: Array<RecipeIngredientType>;
  meta?: Maybe<RecipeMetaType>;
  types: Array<RecipeTypeType>;
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
  lenusUuid?: Maybe<Scalars["String"]>;
};

export enum CookingTimeEnum {
  /** UNSPECIFIED */
  Unspecified = "UNSPECIFIED",
  /** +30min */
  Slow = "SLOW",
  /** 15-30 min */
  Medium = "MEDIUM",
  /** 0-15 min */
  Fast = "FAST",
}

export enum ZenfitMacroSplitEnum {
  P30C50F20 = "P30_C50_F20",
  P40C40F20 = "P40_C40_F20",
  P30C10F60 = "P30_C10_F60",
  P35C35F30 = "P35_C35_F30",
  P30C40F30 = "P30_C40_F30",
}

export type RecipeIngredientType = {
  __typename?: "RecipeIngredientType";
  id: Scalars["Int"];
  order?: Maybe<Scalars["Int"]>;
  ingredient?: Maybe<IngredientType>;
  amount?: Maybe<Scalars["Int"]>;
  unit?: Maybe<MealIngredientUnitType>;
  unitAmount?: Maybe<Scalars["Float"]>;
};

export type RecipeMetaType = {
  __typename?: "RecipeMetaType";
  id: Scalars["Int"];
  /** If recipe contains lactose */
  lactose: Scalars["Boolean"];
  /** If recipe contains gluten */
  gluten: Scalars["Boolean"];
  /** If recipe contains nuts */
  nuts: Scalars["Boolean"];
  /** If recipe contains eggs */
  eggs: Scalars["Boolean"];
  /** If recipe contains pig */
  pig: Scalars["Boolean"];
  /** If client contains shellfish */
  shellfish: Scalars["Boolean"];
  /** If recipe contains fish */
  fish: Scalars["Boolean"];
  /** If recipe is vegetarian */
  isVegetarian: Scalars["Boolean"];
  /** If recipe is vegan */
  isVegan: Scalars["Boolean"];
  /** If client / recipe is pescetarian */
  isPescetarian: Scalars["Boolean"];
};

export type RecipeTypeType = {
  __typename?: "RecipeTypeType";
  id: Scalars["Int"];
  /** The type of the meal */
  type?: Maybe<MealTypeEnum>;
};

export type RecipeOrderByInputType = {
  /** The field you want to order by */
  field?: InputMaybe<RecipeOrderByFieldEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

export enum RecipeOrderByFieldEnum {
  Id = "id",
}

export type RecipeFiltersInputType = {
  search?: InputMaybe<Scalars["String"]>;
  mealTypes?: InputMaybe<Array<MealTypeEnum>>;
  cookingTimes?: InputMaybe<Array<CookingTimeEnum>>;
  macroSplits?: InputMaybe<Array<LenusMacroSplitEnum>>;
  ownRecipes?: InputMaybe<Scalars["Boolean"]>;
};

export type RecipeTypePagination = {
  __typename?: "RecipeTypePagination";
  /** List of items on the current page */
  data: Array<RecipeType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type SavedWorkoutType = {
  __typename?: "SavedWorkoutType";
  id: Scalars["Int"];
  /** Is in seconds */
  time?: Maybe<Scalars["String"]>;
  /** The exercises */
  comment?: Maybe<Scalars["String"]>;
  date: Scalars["String"];
  day: WorkoutDayType;
};

export type PlansFilterInputType = {
  currency?: InputMaybe<CurrencyEnum>;
};

export type PlanTypePagination = {
  __typename?: "PlanTypePagination";
  /** List of items on the current page */
  data: Array<PlanType>;
  /** Number of total items selected by the query */
  total: Scalars["Int"];
  /** Number of items returned per page */
  per_page: Scalars["Int"];
  /** Current page of the cursor */
  current_page: Scalars["Int"];
  /** Number of the first item returned */
  from?: Maybe<Scalars["Int"]>;
  /** Number of the last item returned */
  to?: Maybe<Scalars["Int"]>;
  /** The last page (number of pages) */
  last_page: Scalars["Int"];
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  createApiKey: ApiKeyType;
  deleteApiKey?: Maybe<Scalars["Boolean"]>;
  clientSetup: ClientType;
  /** A mutation to update client info (on Trainer side) */
  updateClientInfo: ClientType;
  activateClient: ClientType;
  changeClientActiveState: ClientType;
  /** Edit a client's goal */
  clientGoal: ClientType;
  createClient: ClientType;
  deleteClients?: Maybe<Scalars["Boolean"]>;
  deleteLatestPendingPayment?: Maybe<Scalars["Boolean"]>;
  /** Ignore client reminder */
  ignoreClientReminder: ClientReminderType;
  /** Ignore client status */
  ignoreClientStatus: ClientStatusType;
  anonymizeClientContactInfo?: Maybe<Scalars["Boolean"]>;
  presignedRequest: PresignedRequestType;
  deleteConversation?: Maybe<Scalars["Boolean"]>;
  deleteMessage?: Maybe<Scalars["Boolean"]>;
  sendMessage: MessageType;
  updateMessage: MessageType;
  createLead: LeadType;
  updateLead: LeadType;
  updateLeadTransferredId: LeadType;
  addIngredientToMeal: MealTypeType;
  cloneMealPlanTemplate: MealPlanType;
  createMealPlanTemplate: MealPlanType;
  deleteMealIngredient?: Maybe<Scalars["Boolean"]>;
  deleteMeal?: Maybe<Scalars["Boolean"]>;
  deleteMealPlan?: Maybe<Scalars["Boolean"]>;
  addIngredientToRecipe: RecipeIngredientType;
  createRecipe: RecipeType;
  deleteRecipe?: Maybe<Scalars["Boolean"]>;
  removeRecipeIngredient?: Maybe<Scalars["Boolean"]>;
  updateRecipe: RecipeType;
  updateRecipeIngredient: RecipeIngredientType;
  reorderRecipeIngredient: RecipeIngredientType;
  /** Update the image of a recipe */
  updateRecipeImage: RecipeType;
  updateIngredient: IngredientType;
  updateUser: CoachType;
  updateUserSettings: CoachType;
  addExerciseToWorkoutDay: WorkoutType;
  createWorkoutDay: WorkoutDayType;
  deleteWorkoutDay?: Maybe<Scalars["Boolean"]>;
  deleteWorkout?: Maybe<Scalars["Boolean"]>;
  reorderWorkout: WorkoutType;
  saveWorkoutPlanAsTemplate: WorkoutPlanType;
  updateWorkoutDay: WorkoutDayType;
  updateWorkout: WorkoutType;
  updateWorkoutPlan: WorkoutPlanType;
  duplicateWorkoutDay: WorkoutDayType;
  moveWorkout: WorkoutType;
  updateExercise: ExerciseType;
  initiateLenusTransfer?: Maybe<Scalars["Boolean"]>;
  updateDocument: DocumentType;
  updateVideo: VideoType;
  updateMealPlan: MealPlanType;
  updateClient: ClientType;
};

export type MutationCreateApiKeyArgs = {
  input?: InputMaybe<CreateApiKeyInputType>;
};

export type MutationDeleteApiKeyArgs = {
  id: Scalars["Int"];
};

export type MutationClientSetupArgs = {
  datakey: Scalars["String"];
  input: ClientSetupInputType;
};

export type MutationUpdateClientInfoArgs = {
  clientId: Scalars["Int"];
  input: ClientSetupInputType;
};

export type MutationActivateClientArgs = {
  clientId: Scalars["Int"];
};

export type MutationChangeClientActiveStateArgs = {
  clientId: Scalars["Int"];
  input: ClientActiveStateInputType;
};

export type MutationClientGoalArgs = {
  clientId: Scalars["Int"];
  input: ClientGoalInputType;
};

export type MutationCreateClientArgs = {
  input?: InputMaybe<CreateClientInputType>;
};

export type MutationDeleteClientsArgs = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type MutationDeleteLatestPendingPaymentArgs = {
  clientId: Scalars["Int"];
};

export type MutationIgnoreClientReminderArgs = {
  clientReminderId: Scalars["Int"];
};

export type MutationIgnoreClientStatusArgs = {
  clientStatusId: Scalars["Int"];
};

export type MutationAnonymizeClientContactInfoArgs = {
  clientId: Scalars["Int"];
};

export type MutationPresignedRequestArgs = {
  contentType: Scalars["String"];
};

export type MutationDeleteConversationArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMessageArgs = {
  id: Scalars["Int"];
};

export type MutationSendMessageArgs = {
  clientId: Scalars["Int"];
  input: MessageSendInputType;
};

export type MutationUpdateMessageArgs = {
  id: Scalars["Int"];
  input: UpdateMessageInputType;
};

export type MutationCreateLeadArgs = {
  input?: InputMaybe<CreateLeadInputType>;
};

export type MutationUpdateLeadArgs = {
  id: Scalars["Int"];
  input: CreateLeadInputType;
};

export type MutationUpdateLeadTransferredIdArgs = {
  id: Scalars["Int"];
  input: UpdateLeadTransferredIdInputType;
};

export type MutationAddIngredientToMealArgs = {
  mealId: Scalars["Int"];
  input?: InputMaybe<AddIngredientToMealInputType>;
};

export type MutationCloneMealPlanTemplateArgs = {
  mealPlanTemplateId: Scalars["Int"];
  input: CloneMealPlanTemplateInputType;
};

export type MutationCreateMealPlanTemplateArgs = {
  input?: InputMaybe<CreateMealPlanTemplateInputType>;
};

export type MutationDeleteMealIngredientArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMealArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMealPlanArgs = {
  id: Scalars["Int"];
};

export type MutationAddIngredientToRecipeArgs = {
  input?: InputMaybe<RecipeIngredientInputType>;
};

export type MutationCreateRecipeArgs = {
  input?: InputMaybe<CreateRecipeInputType>;
};

export type MutationDeleteRecipeArgs = {
  id: Scalars["Int"];
};

export type MutationRemoveRecipeIngredientArgs = {
  id: Scalars["Int"];
};

export type MutationUpdateRecipeArgs = {
  id: Scalars["Int"];
  input: UpdateRecipeInputType;
};

export type MutationUpdateRecipeIngredientArgs = {
  id: Scalars["Int"];
  input: UpdateRecipeIngredientInputType;
};

export type MutationReorderRecipeIngredientArgs = {
  recipeIngredientId: Scalars["Int"];
  order?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateRecipeImageArgs = {
  recipeId: Scalars["Int"];
  input: UpdateRecipeImageInputType;
};

export type MutationUpdateIngredientArgs = {
  id: Scalars["Int"];
  input: UpdateIngredientInputType;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInputType>;
};

export type MutationUpdateUserSettingsArgs = {
  input?: InputMaybe<UpdateUserSettingsInputType>;
};

export type MutationAddExerciseToWorkoutDayArgs = {
  workoutDayId: Scalars["Int"];
  input?: InputMaybe<AddExerciseToWorkoutDayInputType>;
};

export type MutationCreateWorkoutDayArgs = {
  workoutPlanId: Scalars["Int"];
  input?: InputMaybe<WorkoutDayInputType>;
};

export type MutationDeleteWorkoutDayArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteWorkoutArgs = {
  id: Scalars["Int"];
};

export type MutationReorderWorkoutArgs = {
  workoutDayId: Scalars["Int"];
  input?: InputMaybe<ReorderWorkoutInputType>;
};

export type MutationSaveWorkoutPlanAsTemplateArgs = {
  id: Scalars["Int"];
  input: WorkoutPlanAsTemplateInputType;
};

export type MutationUpdateWorkoutDayArgs = {
  id: Scalars["Int"];
  input: UpdateWorkoutDayInputType;
};

export type MutationUpdateWorkoutArgs = {
  id: Scalars["Int"];
  input: UpdateWorkoutInputType;
};

export type MutationUpdateWorkoutPlanArgs = {
  id: Scalars["Int"];
  input: UpdateWorkoutPlanInputType;
};

export type MutationDuplicateWorkoutDayArgs = {
  id: Scalars["Int"];
  input: WorkoutDayInputType;
};

export type MutationMoveWorkoutArgs = {
  input?: InputMaybe<MoveWorkoutInputType>;
};

export type MutationUpdateExerciseArgs = {
  id: Scalars["Int"];
  input: UpdateExerciseInputType;
};

export type MutationInitiateLenusTransferArgs = {
  input: InitiateLenusTransferInputType;
};

export type MutationUpdateDocumentArgs = {
  id: Scalars["Int"];
  input: UpdateDocumentInputType;
};

export type MutationUpdateVideoArgs = {
  id: Scalars["Int"];
  input: UpdateVideoInputType;
};

export type MutationUpdateMealPlanArgs = {
  id: Scalars["Int"];
  input: UpdateMealPlanInputType;
};

export type MutationUpdateClientArgs = {
  id: Scalars["Int"];
  input: UpdateClientInputType;
};

export type CreateApiKeyInputType = {
  name: Scalars["String"];
};

/** A type to setup client data */
export type ClientSetupInputType = {
  /** The full name of the client */
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  /** The email of the client */
  email?: InputMaybe<Scalars["String"]>;
  /** Pal is the activity level of the client */
  pal?: InputMaybe<ClientPalEnum>;
  /** The location of the workout */
  workoutLocation?: InputMaybe<WorkoutLocationEnum>;
  /** Desc */
  workoutExperience?: InputMaybe<Scalars["String"]>;
  /** Desc */
  workoutExperienceLevel?: InputMaybe<WorkoutExperienceLevelEnum>;
  /** Desc */
  workoutPreference?: InputMaybe<Scalars["String"]>;
  /** Desc */
  motivation?: InputMaybe<Scalars["String"]>;
  /** The birthdate of the client */
  birthday?: InputMaybe<Scalars["String"]>;
  /** Type of measuring system */
  measuringSystem?: InputMaybe<MeasuringSystemEnum>;
  /** The number of meals per day */
  numberOfMeals?: InputMaybe<Scalars["Int"]>;
  /** Desc */
  workoutsPerWeek?: InputMaybe<Scalars["Int"]>;
  /** The photo of the client */
  photo?: InputMaybe<Scalars["String"]>;
  /** The phone number of the client */
  phone?: InputMaybe<Scalars["String"]>;
  /** The height in centimeters */
  height?: InputMaybe<Scalars["Float"]>;
  /** The height in feet */
  feet?: InputMaybe<Scalars["Float"]>;
  /** The height in inches */
  inches?: InputMaybe<Scalars["Float"]>;
  /** Lifestyle habits */
  lifestyle?: InputMaybe<Scalars["String"]>;
  /** The clients weight goal */
  weightGoal?: InputMaybe<Scalars["Float"]>;
  /** A note about the clients diet style */
  dietStyle?: InputMaybe<Scalars["String"]>;
  /** The gender of the client */
  gender?: InputMaybe<GenderEnum>;
  /** The locale of the client */
  locale?: InputMaybe<LocaleEnum>;
  /** Injuries text */
  injuries?: InputMaybe<Scalars["String"]>;
  /** Desc */
  other?: InputMaybe<Scalars["String"]>;
  /** The start weight in kilos as a float */
  startWeight?: InputMaybe<Scalars["Float"]>;
  /** The date on which the client starts at their trainer */
  startDate?: InputMaybe<Scalars["String"]>;
  /** The date on which the client ends at their trainer */
  endDate?: InputMaybe<Scalars["String"]>;
  /** Determine how often the meal plans should be updated */
  mealPlanSchedule?: InputMaybe<ClientPlansScheduleEnum>;
  /** Determine how often the workout plans should be updated */
  workoutPlanSchedule?: InputMaybe<ClientPlansScheduleEnum>;
  /** The personal note of the trainer related to the client */
  note?: InputMaybe<Scalars["String"]>;
  /** Hide/show calories in the client app */
  hideCalories?: InputMaybe<Scalars["Boolean"]>;
  /** Custom questions - format: JSON array: [{"id":1, "answer": "yes"}, {"id":2, "answer": "no"}] */
  customQuestions?: InputMaybe<Scalars["String"]>;
  /** Food pref */
  clientFoodPreferences?: InputMaybe<ClientFoodPreferencesInputType>;
  /** Is questionnaire yes / no (if trainer is editing - should ALWAYS be FALSE!) */
  isQuestionnaire?: InputMaybe<Scalars["Boolean"]>;
  /** The day the client checks in */
  checkInDay?: InputMaybe<Scalars["Int"]>;
  /** The frequency of the day the client checks in */
  checkInFrequency?: InputMaybe<Scalars["Int"]>;
  /** The push notification of checkin date of the client checks in */
  checkInReminderLastSent?: InputMaybe<Scalars["String"]>;
  /** Client tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type ClientFoodPreferencesInputType = {
  /** If client wants lactose */
  lactose?: InputMaybe<Scalars["Boolean"]>;
  /** If client wants gluten */
  gluten?: InputMaybe<Scalars["Boolean"]>;
  /** If client wants nuts */
  nuts?: InputMaybe<Scalars["Boolean"]>;
  /** If client wants eggs */
  eggs?: InputMaybe<Scalars["Boolean"]>;
  /** If client wants pig */
  pig?: InputMaybe<Scalars["Boolean"]>;
  /** If client wants shellfish */
  shellfish?: InputMaybe<Scalars["Boolean"]>;
  /** If client wants fish */
  fish?: InputMaybe<Scalars["Boolean"]>;
  /** If client is vegetarian */
  isVegetarian?: InputMaybe<Scalars["Boolean"]>;
  /** If client is vegan */
  isVegan?: InputMaybe<Scalars["Boolean"]>;
  /** If client is pescetarian */
  isPescetarian?: InputMaybe<Scalars["Boolean"]>;
  /** If client has eating disorder */
  uncomfortablyFull?: InputMaybe<Scalars["Boolean"]>;
  /** If client has eating disorder */
  lostControl?: InputMaybe<Scalars["Boolean"]>;
  /** If client has eating disorder */
  significantLoss?: InputMaybe<Scalars["Boolean"]>;
  /** If client has eating disorder */
  feelsFat?: InputMaybe<Scalars["Boolean"]>;
  /** If client has eating disorder */
  foodDominates?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientActiveStateInputType = {
  active?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientGoalInputType = {
  goalWeight: Scalars["Float"];
  dailySteps?: InputMaybe<Scalars["Int"]>;
};

export type CreateClientInputType = {
  name: Scalars["String"];
  email: Scalars["String"];
  gender?: InputMaybe<Scalars["String"]>;
};

export type PresignedRequestType = {
  __typename?: "PresignedRequestType";
  filename: Scalars["String"];
  url: Scalars["String"];
  s3Url: Scalars["String"];
};

export type MessageSendInputType = {
  content?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
};

export type UpdateMessageInputType = {
  new?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateLeadInputType = {
  /** The name of the lead */
  name: Scalars["String"];
  /** The email of the lead */
  email: Scalars["String"];
  /** The phone number of the lead */
  phone: Scalars["String"];
  /** The answers to the questionnaire */
  salesNotes?: InputMaybe<Scalars["String"]>;
  /** When coach should follow up */
  followUpAt?: InputMaybe<Scalars["String"]>;
  /** Status of lead */
  status?: InputMaybe<LeadStatusEnum>;
};

export enum LeadStatusEnum {
  /** New */
  New = "NEW",
  /** In Dialog */
  InDialog = "IN_DIALOG",
  /** Won */
  Won = "WON",
  /** Lost */
  Lost = "LOST",
  /** Payment Pending */
  PaymentPending = "PAYMENT_PENDING",
  /** No Answer */
  NoAnswer = "NO_ANSWER",
}

export type UpdateLeadTransferredIdInputType = {
  /** The id of the lead in Lenus */
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type AddIngredientToMealInputType = {
  ingredientId: Scalars["Int"];
  order: Scalars["Int"];
  amount: Scalars["Int"];
};

export type CloneMealPlanTemplateInputType = {
  name: Scalars["String"];
  comment?: InputMaybe<Scalars["String"]>;
};

export type CreateMealPlanTemplateInputType = {
  name: Scalars["String"];
  comment?: InputMaybe<Scalars["String"]>;
  types?: InputMaybe<Array<MealTypeDistributionInputType>>;
};

export type MealTypeDistributionInputType = {
  type?: InputMaybe<MealTypeEnum>;
  percentage?: InputMaybe<Scalars["Float"]>;
  lenusMacroSplit?: InputMaybe<LenusMacroSplitEnum>;
  zenfitMacroSplit?: InputMaybe<ZenfitMacroSplitEnum>;
};

export type RecipeIngredientInputType = {
  ingredientId: Scalars["Int"];
  recipeId: Scalars["Int"];
  amount: Scalars["Int"];
  order: Scalars["Int"];
};

export type CreateRecipeInputType = {
  macroSplit: LenusMacroSplitEnum;
  image?: InputMaybe<Scalars["String"]>;
  cookingTime?: InputMaybe<CookingTimeEnum>;
  servings?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<LocaleEnum>;
  name: Scalars["String"];
  instructions?: InputMaybe<Scalars["String"]>;
  types: Array<MealTypeEnum>;
  preferences?: InputMaybe<RecipePreferencesInputType>;
};

export type RecipePreferencesInputType = {
  /** Contains lactose */
  lactose?: InputMaybe<Scalars["Boolean"]>;
  /** Contains gluten */
  gluten?: InputMaybe<Scalars["Boolean"]>;
  /** Contains nuts */
  nuts?: InputMaybe<Scalars["Boolean"]>;
  /** Contains eggs */
  eggs?: InputMaybe<Scalars["Boolean"]>;
  /** Contains pig */
  pig?: InputMaybe<Scalars["Boolean"]>;
  /** Contains shellfish */
  shellfish?: InputMaybe<Scalars["Boolean"]>;
  /** Contains fish */
  fish?: InputMaybe<Scalars["Boolean"]>;
  /** Is a vegetarian recipe */
  isVegetarian?: InputMaybe<Scalars["Boolean"]>;
  /** Is a vegan recipe */
  isVegan?: InputMaybe<Scalars["Boolean"]>;
  /** Is a pescetarian recipe */
  isPescetarian?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateRecipeInputType = {
  macroSplit?: InputMaybe<LenusMacroSplitEnum>;
  image?: InputMaybe<Scalars["String"]>;
  cookingTime?: InputMaybe<CookingTimeEnum>;
  servings?: InputMaybe<Scalars["Int"]>;
  locale: LocaleEnum;
  name?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  types?: Array<MealTypeEnum>;
  preferences?: InputMaybe<RecipePreferencesInputType>;
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type UpdateRecipeIngredientInputType = {
  amount?: InputMaybe<Scalars["Int"]>;
  unitId?: InputMaybe<Scalars["Int"]>;
  unitAmount?: InputMaybe<Scalars["Float"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type UpdateRecipeImageInputType = {
  image: Scalars["String"];
};

export type UpdateIngredientInputType = {
  /** The id of the ingredient in Lenus */
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserInputType = {
  /** The name of the user */
  name?: InputMaybe<Scalars["String"]>;
  /** The company name of the user */
  companyName?: InputMaybe<Scalars["String"]>;
  /** The company logo of the user */
  companyLogo?: InputMaybe<Scalars["String"]>;
  /** Set the use of lenus recipes */
  useLenusRecipes?: InputMaybe<Scalars["Boolean"]>;
  /** Set the use of lenus exercises */
  useLenusExercises?: InputMaybe<Scalars["Boolean"]>;
  /** Global feature flag of coach */
  isExperimental?: InputMaybe<Scalars["Boolean"]>;
  /** Global feature flag of coach */
  version?: InputMaybe<VersionEnum>;
  /** The email of the coach */
  email?: InputMaybe<Scalars["String"]>;
  /** If the coach is active */
  activated?: InputMaybe<Scalars["Boolean"]>;
  /** The country of the coach */
  country?: InputMaybe<Scalars["String"]>;
  /** The Stripe customer identifier */
  stripeCustomerNumber?: InputMaybe<Scalars["String"]>;
  /** The Stripe subscription identifier */
  stripeSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** The Plan */
  planId?: InputMaybe<Scalars["Int"]>;
  /** The date the trial ends */
  trialEndDate?: InputMaybe<Scalars["String"]>;
  subscribedAt?: InputMaybe<Scalars["String"]>;
  unsubscribedAt?: InputMaybe<Scalars["String"]>;
  /** Disable client creation */
  disableClientCreation?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserSettingsInputType = {
  clientFilters?: InputMaybe<Scalars["String"]>;
};

export type AddExerciseToWorkoutDayInputType = {
  /** The exercise id of the exercise */
  exerciseId: Scalars["Int"];
  /** If the workout is in a section / superset */
  parentId?: InputMaybe<Scalars["Int"]>;
  /** Order of the exercise */
  order: Scalars["Int"];
  /** Info about the exercise */
  info?: InputMaybe<Scalars["String"]>;
  /** Exercise comment */
  comment?: InputMaybe<Scalars["String"]>;
  /** Number of reps */
  reps?: InputMaybe<Scalars["String"]>;
  /** Rest time */
  rest?: InputMaybe<Scalars["String"]>;
  /** Number of sets */
  sets?: InputMaybe<Scalars["String"]>;
  /** Start weight */
  startWeight?: InputMaybe<Scalars["String"]>;
  /** Tempo */
  tempo?: InputMaybe<Scalars["String"]>;
  /** RM */
  rm?: InputMaybe<Scalars["String"]>;
  /** Time */
  time?: InputMaybe<Scalars["String"]>;
};

export type WorkoutDayInputType = {
  /** The name of the workout day */
  name: Scalars["String"];
  /** The description of the workout day */
  description?: InputMaybe<Scalars["String"]>;
};

export type ReorderWorkoutInputType = {
  workoutId?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  parentId?: InputMaybe<Scalars["Int"]>;
};

export type WorkoutPlanAsTemplateInputType = {
  /** Name of template */
  name: Scalars["String"];
  /** Comment for template */
  comment?: InputMaybe<Scalars["String"]>;
};

export type UpdateWorkoutDayInputType = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type UpdateWorkoutInputType = {
  /** The reps of the workout */
  reps?: InputMaybe<Scalars["String"]>;
  /** The sets of the workout */
  sets?: InputMaybe<Scalars["String"]>;
  /** The rest of the workout */
  rest?: InputMaybe<Scalars["String"]>;
  /** The time of the workout */
  time?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<WorkoutGroupEnum>;
  title?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  workoutSets?: InputMaybe<Array<InputMaybe<WorkoutSetInputType>>>;
  supersetTitle?: InputMaybe<Scalars["String"]>;
  supersetComment?: InputMaybe<Scalars["String"]>;
};

export enum WorkoutGroupEnum {
  Exercise = "EXERCISE",
  Superset = "SUPERSET",
}

export type WorkoutSetInputType = {
  id?: InputMaybe<Scalars["Int"]>;
  reps?: InputMaybe<Scalars["String"]>;
  rest?: InputMaybe<Scalars["String"]>;
  orderBy: Scalars["Int"];
};

export type UpdateWorkoutPlanInputType = {
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  /** list of template ids to apply to this workout plan */
  templates?: InputMaybe<Array<Scalars["Int"]>>;
  status?: InputMaybe<WorkoutPlanStatusEnum>;
  workoutsPerWeek?: InputMaybe<Scalars["Int"]>;
  level?: InputMaybe<WorkoutExperienceLevelEnum>;
  gender?: InputMaybe<GenderEnum>;
  location?: InputMaybe<WorkoutLocationEnum>;
};

export enum WorkoutPlanStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Hidden = "HIDDEN",
}

export type MoveWorkoutInputType = {
  /** The ID of the workout to move. */
  workoutId: Scalars["Int"];
  /** The ID of the workout day to move the workout to. */
  destinationWorkoutDayId: Scalars["Int"];
};

export type UpdateExerciseInputType = {
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type InitiateLenusTransferInputType = {
  token: Scalars["String"];
  subject?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  data: Array<LenusDataTypeEnum>;
  platform?: InputMaybe<LenusPlatformTypeEnum>;
};

export enum LenusDataTypeEnum {
  Clients = "CLIENTS",
  WorkoutTemplates = "WORKOUT_TEMPLATES",
  Recipes = "RECIPES",
  Leads = "LEADS",
  Exercises = "EXERCISES",
}

export enum LenusPlatformTypeEnum {
  Eu = "EU",
  Us = "US",
}

export type UpdateDocumentInputType = {
  /** The id of the document in Lenus */
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type DocumentType = {
  __typename?: "DocumentType";
  id: Scalars["Int"];
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
};

export type UpdateVideoInputType = {
  /** The id of the video in Lenus */
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type VideoType = {
  __typename?: "VideoType";
  id: Scalars["Int"];
  migratedLenusOwnedId?: Maybe<Scalars["String"]>;
};

export type UpdateMealPlanInputType = {
  /** The id of the meal plan template in Lenus */
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

export type UpdateClientInputType = {
  /** The id of the profile in Lenus */
  migratedLenusOwnedId?: InputMaybe<Scalars["String"]>;
};

/** An integer range input type */
export type IntegerRangeInputType = {
  /** Min */
  from?: InputMaybe<Scalars["Int"]>;
  /** Max */
  to?: InputMaybe<Scalars["Int"]>;
};

export enum QuestionnaireQuestionInputTypeEnum {
  InputTypeInput = "INPUT_TYPE_INPUT",
  InputTypeSlider = "INPUT_TYPE_SLIDER",
  InputTypeSingleChoice = "INPUT_TYPE_SINGLE_CHOICE",
  InputTypeMultipleChoice = "INPUT_TYPE_MULTIPLE_CHOICE",
  InputTypeMultipleInput = "INPUT_TYPE_MULTIPLE_INPUT",
}

export type QuestionnaireQuestionInputType = {
  text: Scalars["String"];
  inputType?: QuestionnaireQuestionInputTypeEnum;
};

export type ClientTagType = {
  __typename?: "ClientTagType";
  id: Scalars["Int"];
  /** Title of tag */
  title: Scalars["String"];
};

export type ConversationType = {
  __typename?: "ConversationType";
  messages: Array<MessageType>;
  id: Scalars["Int"];
};

export type CreateMealPlanFoodPreferencesInputType = {
  preferences?: InputMaybe<ClientFoodPreferencesInputType>;
  excludedIngredients?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  macros?: InputMaybe<MealPlanPreferenceMacroInputType>;
  cookingTime?: InputMaybe<CookingTimeEnum>;
};

export type MealPlanPreferenceMacroInputType = {
  fat?: InputMaybe<Scalars["Int"]>;
  carbohydrate?: InputMaybe<Scalars["Int"]>;
  protein?: InputMaybe<Scalars["Int"]>;
};

export type CreateMealPlanInputType = {
  title: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<LocaleEnum>;
  types?: InputMaybe<Array<MealTypeDistributionInputType>>;
  macroSplit?: InputMaybe<LenusMacroSplitEnum>;
  desiredKcals?: InputMaybe<Scalars["Int"]>;
  /** The number of alternatives */
  numberOfAlternatives?: InputMaybe<Scalars["Int"]>;
  recipeFilters?: InputMaybe<RecipeFiltersInputType>;
  foodPreferences?: InputMaybe<CreateMealPlanFoodPreferencesInputType>;
};

export type ReorderRecipeIngredientInputType = {
  recipeIngredientId?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type UsersFilterInputType = {
  search?: InputMaybe<Scalars["String"]>;
  /** Country filter */
  countries?: InputMaybe<Array<Scalars["String"]>>;
  active?: InputMaybe<Scalars["Boolean"]>;
  /** An array of subscription ids to filter for */
  planIds?: InputMaybe<Array<Scalars["Int"]>>;
  /** Only retrieve admin users */
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  /** Only retrieve assistants */
  isAssistant?: InputMaybe<Scalars["Boolean"]>;
  /** When it churned */
  unsubscribedBetween?: InputMaybe<TimePeriodInputType>;
  subscribedBetween?: InputMaybe<TimePeriodInputType>;
  createdBetween?: InputMaybe<TimePeriodInputType>;
  trialEndBetween?: InputMaybe<TimePeriodInputType>;
  clientCountBetween?: InputMaybe<IntegerRangeInputType>;
  /** Whether to include internal/test users */
  includeTest?: InputMaybe<Scalars["Boolean"]>;
};

export type UsersOrderByInputType = {
  /** The field you want to order by */
  field?: InputMaybe<UserOrderByFieldEnum>;
  /** Sorting direction */
  sort?: InputMaybe<SortingDirectionEnum>;
};

export type CreateSectionInputType = {
  order: Scalars["Int"];
  title?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["Int"]>;
};

export type GetClientContainerQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientContainerQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    photo?: string | null;
    tags?: Array<string> | null;
    hasCreatedPassword: boolean;
    answeredQuestionnaire: boolean;
    url: string;
    coach: { __typename?: "CoachType"; id: number };
  };
};

export type GetClientModalSubscriptionQueryVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type GetClientModalSubscriptionQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    email: string;
    locale?: LocaleEnum | null;
    coach: {
      __typename?: "CoachType";
      id: number;
      isConnectedToStripeConnect: boolean;
      stripeConnectUrl?: string | null;
    };
    paymentConfiguration?: {
      __typename?: "ClientPaymentConfigurationType";
      id: number;
      createdAt?: string | null;
      endDate?: string | null;
      months: number;
      isCharged: boolean;
      terms?: string | null;
      status: ClientPaymentConfigurationStatusEnum;
      recurringFee: number;
      upfrontFee: number;
      currency: CurrencyEnum;
      url: string;
      subscription?: {
        __typename?: "ClientStripeType";
        id: number;
        pausedUntil?: string | null;
        unsubscribedDate?: string | null;
        upcomingInvoiceDueDate?: string | null;
      } | null;
    } | null;
  };
};

export type GetClientPaymentConfigurationQueryVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type GetClientPaymentConfigurationQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    paymentConfiguration?: {
      __typename?: "ClientPaymentConfigurationType";
      id: number;
      createdAt?: string | null;
      endDate?: string | null;
      months: number;
      isCharged: boolean;
      terms?: string | null;
      status: ClientPaymentConfigurationStatusEnum;
      recurringFee: number;
      upfrontFee: number;
      currency: CurrencyEnum;
      url: string;
      subscription?: {
        __typename?: "ClientStripeType";
        id: number;
        pausedUntil?: string | null;
        unsubscribedDate?: string | null;
        upcomingInvoiceDueDate?: string | null;
      } | null;
    } | null;
  };
};

export type GetMealTemplateQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetMealTemplateQuery = {
  __typename?: "Query";
  mealPlanTemplate: {
    __typename?: "MealPlanType";
    id: number;
    title: string;
    description?: string | null;
    migratedLenusOwnedId?: string | null;
    totals?: { __typename?: "MealPlanTotalsType"; numberOfMeals?: number | null } | null;
  };
};

export type GetClientInvitationModalQueryVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type GetClientInvitationModalQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    email: string;
    locale?: LocaleEnum | null;
  };
};

export type CreateMealPlanTemplateMutationVariables = Exact<{
  input?: InputMaybe<CreateMealPlanTemplateInputType>;
}>;

export type CreateMealPlanTemplateMutation = {
  __typename?: "Mutation";
  createMealPlanTemplate: {
    __typename?: "MealPlanType";
    id: number;
    title: string;
    description?: string | null;
    totals?: { __typename?: "MealPlanTotalsType"; numberOfMeals?: number | null } | null;
  };
};

export type GetClientTrackedWorkoutsQueryVariables = Exact<{
  savedWorkoutId: Scalars["Int"];
}>;

export type GetClientTrackedWorkoutsQuery = {
  __typename?: "Query";
  savedWorkout: {
    __typename?: "SavedWorkoutType";
    id: number;
    time?: string | null;
    comment?: string | null;
    day: {
      __typename?: "WorkoutDayType";
      id: number;
      name?: string | null;
      exercises: Array<{
        __typename?: "WorkoutType";
        id: number;
        title?: string | null;
        rest?: string | null;
        reps?: string | null;
        sets?: string | null;
        time?: string | null;
        children?: Array<{
          __typename?: "WorkoutType";
          id: number;
          title?: string | null;
          rest?: string | null;
          reps?: string | null;
          sets?: string | null;
          time?: string | null;
          exercise?: {
            __typename?: "ExerciseType";
            id: number;
            title: string;
            image?: string | null;
          } | null;
          tracking: Array<{
            __typename?: "WorkoutTrackingType";
            id: number;
            weight?: string | null;
            reps?: string | null;
            date: string;
          }>;
        } | null> | null;
        exercise?: {
          __typename?: "ExerciseType";
          id: number;
          title: string;
          image?: string | null;
        } | null;
        tracking: Array<{
          __typename?: "WorkoutTrackingType";
          id: number;
          weight?: string | null;
          reps?: string | null;
          date: string;
        }>;
      } | null>;
    };
  };
};

export type WorkoutTypeFragmentFragment = {
  __typename?: "WorkoutType";
  id: number;
  title?: string | null;
  rest?: string | null;
  reps?: string | null;
  sets?: string | null;
  time?: string | null;
  exercise?: {
    __typename?: "ExerciseType";
    id: number;
    title: string;
    image?: string | null;
  } | null;
  tracking: Array<{
    __typename?: "WorkoutTrackingType";
    id: number;
    weight?: string | null;
    reps?: string | null;
    date: string;
  }>;
};

export type ClientListFragmentFragment = {
  __typename?: "ClientType";
  id: number;
  name: string;
  email: string;
  activated: boolean;
  startDate?: string | null;
  endDate?: string | null;
  mealPlanSchedule?: ClientPlansScheduleEnum | null;
  workoutPlanSchedule?: ClientPlansScheduleEnum | null;
  tags?: Array<string> | null;
  totalWeeks?: number | null;
  weekNumber?: number | null;
  lastCheckIn?: string | null;
  unreadMessagesCount?: number | null;
  hasCreatedPassword: boolean;
  locale?: LocaleEnum | null;
  photo?: string | null;
  mealPlans: Array<{ __typename?: "MealPlanType"; id: number; title: string }>;
  workoutPlans: Array<{ __typename?: "WorkoutPlanType"; id: number; name: string }>;
  unresolvedTasks: Array<{
    __typename?: "ClientStatusType";
    id: number;
    date?: string | null;
    event: { __typename?: "EventType"; id: number; name: EventNameEnum; title: string };
  }>;
  reminders: Array<{
    __typename?: "ClientReminderType";
    id: number;
    title: string;
    resolved: boolean;
    dueDate: string;
  }>;
  queue?: {
    __typename?: "ClientQueueType";
    id: number;
    datakey?: string | null;
    date?: string | null;
  } | null;
  coach: { __typename?: "CoachType"; id: number };
  paymentConfiguration?: {
    __typename?: "ClientPaymentConfigurationType";
    id: number;
    currency: CurrencyEnum;
    upfrontFee: number;
    recurringFee: number;
    datakey: string;
    months: number;
    url: string;
    status: ClientPaymentConfigurationStatusEnum;
  } | null;
};

export type GetClientsQueryVariables = Exact<{
  page: Scalars["Int"];
  limit: Scalars["Int"];
  filters?: InputMaybe<ClientFilterInputType>;
  orderBy?: InputMaybe<ClientsOrderByInputType>;
}>;

export type GetClientsQuery = {
  __typename?: "Query";
  me: {
    __typename?: "CoachType";
    id: number;
    tags?: Array<string> | null;
    activeClientCount: number;
    inactiveClientCount: number;
    pendingClientCount: number;
    subscription?: {
      __typename?: "CoachSubscriptionType";
      id: number;
      plan?: { __typename?: "PlanType"; id: number; clientCount: number } | null;
    } | null;
  };
  clients: {
    __typename?: "ClientTypePagination";
    data: Array<{
      __typename?: "ClientType";
      id: number;
      name: string;
      email: string;
      activated: boolean;
      startDate?: string | null;
      endDate?: string | null;
      mealPlanSchedule?: ClientPlansScheduleEnum | null;
      workoutPlanSchedule?: ClientPlansScheduleEnum | null;
      tags?: Array<string> | null;
      totalWeeks?: number | null;
      weekNumber?: number | null;
      lastCheckIn?: string | null;
      unreadMessagesCount?: number | null;
      hasCreatedPassword: boolean;
      locale?: LocaleEnum | null;
      photo?: string | null;
      mealPlans: Array<{ __typename?: "MealPlanType"; id: number; title: string }>;
      workoutPlans: Array<{ __typename?: "WorkoutPlanType"; id: number; name: string }>;
      unresolvedTasks: Array<{
        __typename?: "ClientStatusType";
        id: number;
        date?: string | null;
        event: {
          __typename?: "EventType";
          id: number;
          name: EventNameEnum;
          title: string;
        };
      }>;
      reminders: Array<{
        __typename?: "ClientReminderType";
        id: number;
        title: string;
        resolved: boolean;
        dueDate: string;
      }>;
      queue?: {
        __typename?: "ClientQueueType";
        id: number;
        datakey?: string | null;
        date?: string | null;
      } | null;
      coach: { __typename?: "CoachType"; id: number };
      paymentConfiguration?: {
        __typename?: "ClientPaymentConfigurationType";
        id: number;
        currency: CurrencyEnum;
        upfrontFee: number;
        recurringFee: number;
        datakey: string;
        months: number;
        url: string;
        status: ClientPaymentConfigurationStatusEnum;
      } | null;
    }>;
  };
};

export type GetClientFilterCountQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetClientFilterCountQuery = {
  __typename?: "Query";
  clientFilterCount?: {
    __typename?: "ClientFilterCountType";
    active: number;
    activated: number;
    ending: number;
    updatePlans: number;
    missingCheckIn: number;
    checkIn: number;
    unreadMessage: number;
    noContact: number;
    paymentFailed: number;
    dueReminders: number;
    futureReminders: number;
    requestedUnsubscribe: number;
    pending: number;
    createPlans: number;
    paymentPending: number;
    invitePending: number;
    questionnairePending: number;
    readyForActivation: number;
  } | null;
};

export type ChangeClientActiveStateMutationVariables = Exact<{
  clientId: Scalars["Int"];
  input: ClientActiveStateInputType;
}>;

export type ChangeClientActiveStateMutation = {
  __typename?: "Mutation";
  changeClientActiveState: {
    __typename?: "ClientType";
    id: number;
    name: string;
    email: string;
    activated: boolean;
    startDate?: string | null;
    endDate?: string | null;
    mealPlanSchedule?: ClientPlansScheduleEnum | null;
    workoutPlanSchedule?: ClientPlansScheduleEnum | null;
    tags?: Array<string> | null;
    totalWeeks?: number | null;
    weekNumber?: number | null;
    lastCheckIn?: string | null;
    unreadMessagesCount?: number | null;
    hasCreatedPassword: boolean;
    locale?: LocaleEnum | null;
    photo?: string | null;
    mealPlans: Array<{ __typename?: "MealPlanType"; id: number; title: string }>;
    workoutPlans: Array<{ __typename?: "WorkoutPlanType"; id: number; name: string }>;
    unresolvedTasks: Array<{
      __typename?: "ClientStatusType";
      id: number;
      date?: string | null;
      event: { __typename?: "EventType"; id: number; name: EventNameEnum; title: string };
    }>;
    reminders: Array<{
      __typename?: "ClientReminderType";
      id: number;
      title: string;
      resolved: boolean;
      dueDate: string;
    }>;
    queue?: {
      __typename?: "ClientQueueType";
      id: number;
      datakey?: string | null;
      date?: string | null;
    } | null;
    coach: { __typename?: "CoachType"; id: number };
    paymentConfiguration?: {
      __typename?: "ClientPaymentConfigurationType";
      id: number;
      currency: CurrencyEnum;
      upfrontFee: number;
      recurringFee: number;
      datakey: string;
      months: number;
      url: string;
      status: ClientPaymentConfigurationStatusEnum;
    } | null;
  };
};

export type DeleteClientsMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>;
}>;

export type DeleteClientsMutation = {
  __typename?: "Mutation";
  deleteClients?: boolean | null;
};

export type UpdateClientTagMutationVariables = Exact<{
  clientId: Scalars["Int"];
  input: ClientSetupInputType;
}>;

export type UpdateClientTagMutation = {
  __typename?: "Mutation";
  updateClientInfo: {
    __typename?: "ClientType";
    id: number;
    tags?: Array<string> | null;
  };
};

export type ResolveReminderMutationVariables = Exact<{
  reminderId: Scalars["Int"];
}>;

export type ResolveReminderMutation = {
  __typename?: "Mutation";
  ignoreClientReminder: {
    __typename?: "ClientReminderType";
    id: number;
    title: string;
    dueDate: string;
    resolved: boolean;
  };
};

export type GetClientSubscriptionQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientSubscriptionQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    isDemo: boolean;
    coach: {
      __typename?: "CoachType";
      id: number;
      isConnectedToStripeConnect: boolean;
      stripeConnectUrl?: string | null;
    };
    payments: Array<{
      __typename?: "ClientPaymentType";
      id: number;
      date?: string | null;
      amount?: number | null;
      chargeId?: string | null;
      type: PaymentTypeEnum;
      currency?: CurrencyEnum | null;
      reason?: string | null;
    }>;
    paymentConfiguration?: {
      __typename?: "ClientPaymentConfigurationType";
      id: number;
      createdAt?: string | null;
      endDate?: string | null;
      months: number;
      isCharged: boolean;
      terms?: string | null;
      status: ClientPaymentConfigurationStatusEnum;
      recurringFee: number;
      upfrontFee: number;
      currency: CurrencyEnum;
      url: string;
      subscription?: {
        __typename?: "ClientStripeType";
        id: number;
        pausedUntil?: string | null;
        unsubscribedDate?: string | null;
        upcomingInvoiceDueDate?: string | null;
      } | null;
    } | null;
  };
};

export type DeleteLatestPendingPaymentMutationVariables = Exact<{
  clientId: Scalars["Int"];
}>;

export type DeleteLatestPendingPaymentMutation = {
  __typename?: "Mutation";
  deleteLatestPendingPayment?: boolean | null;
};

export type ResolveTaskMutationVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ResolveTaskMutation = {
  __typename?: "Mutation";
  ignoreClientStatus: { __typename?: "ClientStatusType"; id: number };
};

export type ClientPaymentConfigurationFragmentFragment = {
  __typename?: "ClientType";
  paymentConfiguration?: {
    __typename?: "ClientPaymentConfigurationType";
    id: number;
    createdAt?: string | null;
    endDate?: string | null;
    months: number;
    isCharged: boolean;
    terms?: string | null;
    status: ClientPaymentConfigurationStatusEnum;
    recurringFee: number;
    upfrontFee: number;
    currency: CurrencyEnum;
    url: string;
    subscription?: {
      __typename?: "ClientStripeType";
      id: number;
      pausedUntil?: string | null;
      unsubscribedDate?: string | null;
      upcomingInvoiceDueDate?: string | null;
    } | null;
  } | null;
};

export type GetSubscriptionPlansQueryVariables = Exact<{
  filters: PlansFilterInputType;
  pagination: PaginationInputType;
}>;

export type GetSubscriptionPlansQuery = {
  __typename?: "Query";
  plans: {
    __typename?: "PlanTypePagination";
    data: Array<{
      __typename?: "PlanType";
      id: number;
      active: boolean;
      name: string;
      currency: string;
      clientCount: number;
      country: string;
      isTiered?: boolean | null;
      priceMonth: number;
    }>;
  };
};

export type InitiateLenusTransferMutationVariables = Exact<{
  input: InitiateLenusTransferInputType;
}>;

export type InitiateLenusTransferMutation = {
  __typename?: "Mutation";
  initiateLenusTransfer?: boolean | null;
};

export type GetClientFoodPreferenceQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientFoodPreferenceQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    hideCalories: boolean;
    gender?: GenderEnum | null;
    birthday?: string | null;
    age?: number | null;
    height?: number | null;
    startWeight?: number | null;
    weightGoal?: number | null;
    pal?: ClientPalEnum | null;
    measuringSystem?: MeasuringSystemEnum | null;
    numberOfMeals?: number | null;
    dietStyle?: string | null;
    clientFoodPreferences?: {
      __typename?: "ClientFoodPreferencesType";
      isPescetarian: boolean;
      isVegan: boolean;
      isVegetarian: boolean;
      lactose: boolean;
      pig: boolean;
      shellfish: boolean;
      gluten: boolean;
      nuts: boolean;
      eggs: boolean;
      fish: boolean;
      significantLoss: boolean;
      lostControl: boolean;
      feelsFat: boolean;
      uncomfortablyFull: boolean;
      foodDominates: boolean;
    } | null;
  };
};

export type GetMealTemplatesQueryVariables = Exact<{
  page: Scalars["Int"];
  limit: Scalars["Int"];
  filters?: InputMaybe<MealPlanTemplateFilterInputType>;
  orderBy?: InputMaybe<MealPlanTemplatesOrderByInputType>;
}>;

export type GetMealTemplatesQuery = {
  __typename?: "Query";
  mealPlanTemplates: {
    __typename?: "MealPlanTypePagination";
    total: number;
    data: Array<{
      __typename?: "MealPlanType";
      id: number;
      title: string;
      description?: string | null;
      migratedLenusOwnedId?: string | null;
      totals?: {
        __typename?: "MealPlanTotalsType";
        numberOfMeals?: number | null;
      } | null;
    }>;
  };
};

export type MealPlanTemplateFragmentFragment = {
  __typename?: "MealPlanType";
  id: number;
  title: string;
  description?: string | null;
  migratedLenusOwnedId?: string | null;
  totals?: { __typename?: "MealPlanTotalsType"; numberOfMeals?: number | null } | null;
};

export type DeleteMealTemplateMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteMealTemplateMutation = {
  __typename?: "Mutation";
  deleteMealPlan?: boolean | null;
};

export type UpdateWorkoutPlanMutationVariables = Exact<{
  id: Scalars["Int"];
  input: UpdateWorkoutPlanInputType;
}>;

export type UpdateWorkoutPlanMutation = {
  __typename?: "Mutation";
  updateWorkoutPlan: { __typename?: "WorkoutPlanType"; id: number; name: string };
};

export type SaveWorkoutPlanAsTemplateMutationVariables = Exact<{
  id: Scalars["Int"];
  input: WorkoutPlanAsTemplateInputType;
}>;

export type SaveWorkoutPlanAsTemplateMutation = {
  __typename?: "Mutation";
  saveWorkoutPlanAsTemplate: { __typename?: "WorkoutPlanType"; id: number };
};

export type ClientFragmentFragment = {
  __typename?: "ClientType";
  id: number;
  photo?: string | null;
  name: string;
  email: string;
  locale?: LocaleEnum | null;
  isDemo: boolean;
  phone?: string | null;
  tags?: Array<string> | null;
  startDate?: string | null;
  endDate?: string | null;
  checkInDay?: number | null;
  checkInFrequency?: number | null;
  mealPlanSchedule?: ClientPlansScheduleEnum | null;
  workoutPlanSchedule?: ClientPlansScheduleEnum | null;
  note?: string | null;
  gender?: GenderEnum | null;
  birthday?: string | null;
  age?: number | null;
  measuringSystem?: MeasuringSystemEnum | null;
  startWeight?: number | null;
  height?: number | null;
  feet?: number | null;
  inches?: number | null;
  pal?: ClientPalEnum | null;
  workoutsPerWeek?: number | null;
  workoutLocation?: WorkoutLocationEnum | null;
  workoutExperience?: string | null;
  workoutExperienceLevel?: WorkoutExperienceLevelEnum | null;
  workoutPreference?: string | null;
  injuries?: string | null;
  hideCalories: boolean;
  numberOfMeals?: number | null;
  dietStyle?: string | null;
  motivation?: string | null;
  lifestyle?: string | null;
  other?: string | null;
  clientFoodPreferences?: {
    __typename?: "ClientFoodPreferencesType";
    isPescetarian: boolean;
    isVegan: boolean;
    isVegetarian: boolean;
    lactose: boolean;
    pig: boolean;
    shellfish: boolean;
    gluten: boolean;
    nuts: boolean;
    eggs: boolean;
    fish: boolean;
  } | null;
  customQuestions?: Array<{
    __typename?: "CustomQuestionType";
    id?: number | null;
    answer?: string | null;
    question: string;
  } | null> | null;
};

export type WorkoutSelectionFragment = {
  __typename?: "WorkoutType";
  id: number;
  order?: number | null;
  reps?: string | null;
  sets?: string | null;
  rest?: string | null;
  time?: string | null;
  title?: string | null;
  description?: string | null;
  parentId?: number | null;
  exercise?: {
    __typename?: "ExerciseType";
    id: number;
    title: string;
    image?: string | null;
    video?: string | null;
    isLenusExercise: boolean;
    type?: ExerciseTypeEnum | null;
  } | null;
  children?: Array<{
    __typename?: "WorkoutType";
    id: number;
    order?: number | null;
    reps?: string | null;
    sets?: string | null;
    rest?: string | null;
    time?: string | null;
    title?: string | null;
    description?: string | null;
    parentId?: number | null;
    exercise?: {
      __typename?: "ExerciseType";
      id: number;
      title: string;
      image?: string | null;
      video?: string | null;
      isLenusExercise: boolean;
      type?: ExerciseTypeEnum | null;
    } | null;
  } | null> | null;
};

export type GetWorkoutPlanTemplateQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetWorkoutPlanTemplateQuery = {
  __typename?: "Query";
  workoutPlan: {
    __typename?: "WorkoutPlanType";
    id: number;
    name: string;
    description?: string | null;
    comment?: string | null;
    meta?: {
      __typename?: "WorkoutPlanMetaType";
      id: number;
      level?: WorkoutExperienceLevelEnum | null;
      location?: WorkoutLocationEnum | null;
      workoutsPerWeek?: number | null;
      gender?: GenderEnum | null;
    } | null;
    days?: Array<{
      __typename?: "WorkoutDayType";
      id: number;
      name?: string | null;
      order?: number | null;
      description?: string | null;
      exercises: Array<{
        __typename?: "WorkoutType";
        id: number;
        order?: number | null;
        reps?: string | null;
        sets?: string | null;
        rest?: string | null;
        time?: string | null;
        title?: string | null;
        description?: string | null;
        parentId?: number | null;
        exercise?: {
          __typename?: "ExerciseType";
          id: number;
          title: string;
          image?: string | null;
          video?: string | null;
          isLenusExercise: boolean;
          type?: ExerciseTypeEnum | null;
        } | null;
        children?: Array<{
          __typename?: "WorkoutType";
          id: number;
          order?: number | null;
          reps?: string | null;
          sets?: string | null;
          rest?: string | null;
          time?: string | null;
          title?: string | null;
          description?: string | null;
          parentId?: number | null;
          exercise?: {
            __typename?: "ExerciseType";
            id: number;
            title: string;
            image?: string | null;
            video?: string | null;
            isLenusExercise: boolean;
            type?: ExerciseTypeEnum | null;
          } | null;
        } | null> | null;
      } | null>;
    } | null> | null;
  };
};

export type GetClientGeneralQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientGeneralQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    gender?: GenderEnum | null;
    startWeight?: number | null;
    weightGoal?: number | null;
    answeredQuestionnaire: boolean;
    birthday?: string | null;
    isDemo: boolean;
    locale?: LocaleEnum | null;
    url: string;
    age?: number | null;
    startDate?: string | null;
    checkInDay?: number | null;
    checkInFrequency?: number | null;
    checkInReminderLastSent?: string | null;
    measuringSystem?: MeasuringSystemEnum | null;
    endDate?: string | null;
    note?: string | null;
    integrations?: {
      __typename?: "ClientIntegrationType";
      healthConnected: boolean;
      mfpConnected: boolean;
    } | null;
    coach: { __typename?: "CoachType"; id: number; isConnectedToStripeConnect: boolean };
    unresolvedTasks: Array<{
      __typename?: "ClientStatusType";
      id: number;
      date?: string | null;
      resolved: boolean;
      resolvedBy?: string | null;
      event: { __typename?: "EventType"; id: number; name: EventNameEnum; title: string };
    }>;
    paymentConfiguration?: {
      __typename?: "ClientPaymentConfigurationType";
      id: number;
      status: ClientPaymentConfigurationStatusEnum;
      url: string;
    } | null;
    clientFoodPreferences?: {
      __typename?: "ClientFoodPreferencesType";
      significantLoss: boolean;
      lostControl: boolean;
      feelsFat: boolean;
      uncomfortablyFull: boolean;
      foodDominates: boolean;
    } | null;
  };
};

export type GetClientStatusesQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientStatusesQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    unresolvedTasks: Array<{
      __typename?: "ClientStatusType";
      id: number;
      date?: string | null;
      resolved: boolean;
      resolvedBy?: string | null;
      event: { __typename?: "EventType"; id: number; name: EventNameEnum; title: string };
    }>;
  };
};

export type GetClientSettingsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientSettingsQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    photo?: string | null;
    name: string;
    email: string;
    locale?: LocaleEnum | null;
    isDemo: boolean;
    phone?: string | null;
    tags?: Array<string> | null;
    startDate?: string | null;
    endDate?: string | null;
    checkInDay?: number | null;
    checkInFrequency?: number | null;
    mealPlanSchedule?: ClientPlansScheduleEnum | null;
    workoutPlanSchedule?: ClientPlansScheduleEnum | null;
    note?: string | null;
    gender?: GenderEnum | null;
    birthday?: string | null;
    age?: number | null;
    measuringSystem?: MeasuringSystemEnum | null;
    startWeight?: number | null;
    height?: number | null;
    feet?: number | null;
    inches?: number | null;
    pal?: ClientPalEnum | null;
    workoutsPerWeek?: number | null;
    workoutLocation?: WorkoutLocationEnum | null;
    workoutExperience?: string | null;
    workoutExperienceLevel?: WorkoutExperienceLevelEnum | null;
    workoutPreference?: string | null;
    injuries?: string | null;
    hideCalories: boolean;
    numberOfMeals?: number | null;
    dietStyle?: string | null;
    motivation?: string | null;
    lifestyle?: string | null;
    other?: string | null;
    clientFoodPreferences?: {
      __typename?: "ClientFoodPreferencesType";
      isPescetarian: boolean;
      isVegan: boolean;
      isVegetarian: boolean;
      lactose: boolean;
      pig: boolean;
      shellfish: boolean;
      gluten: boolean;
      nuts: boolean;
      eggs: boolean;
      fish: boolean;
    } | null;
    customQuestions?: Array<{
      __typename?: "CustomQuestionType";
      id?: number | null;
      answer?: string | null;
      question: string;
    } | null> | null;
  };
};

export type UpdateClientSettingsMutationVariables = Exact<{
  id: Scalars["Int"];
  input: ClientSetupInputType;
}>;

export type UpdateClientSettingsMutation = {
  __typename?: "Mutation";
  updateClientInfo: {
    __typename?: "ClientType";
    id: number;
    photo?: string | null;
    name: string;
    email: string;
    locale?: LocaleEnum | null;
    isDemo: boolean;
    phone?: string | null;
    tags?: Array<string> | null;
    startDate?: string | null;
    endDate?: string | null;
    checkInDay?: number | null;
    checkInFrequency?: number | null;
    mealPlanSchedule?: ClientPlansScheduleEnum | null;
    workoutPlanSchedule?: ClientPlansScheduleEnum | null;
    note?: string | null;
    gender?: GenderEnum | null;
    birthday?: string | null;
    age?: number | null;
    measuringSystem?: MeasuringSystemEnum | null;
    startWeight?: number | null;
    height?: number | null;
    feet?: number | null;
    inches?: number | null;
    pal?: ClientPalEnum | null;
    workoutsPerWeek?: number | null;
    workoutLocation?: WorkoutLocationEnum | null;
    workoutExperience?: string | null;
    workoutExperienceLevel?: WorkoutExperienceLevelEnum | null;
    workoutPreference?: string | null;
    injuries?: string | null;
    hideCalories: boolean;
    numberOfMeals?: number | null;
    dietStyle?: string | null;
    motivation?: string | null;
    lifestyle?: string | null;
    other?: string | null;
    clientFoodPreferences?: {
      __typename?: "ClientFoodPreferencesType";
      isPescetarian: boolean;
      isVegan: boolean;
      isVegetarian: boolean;
      lactose: boolean;
      pig: boolean;
      shellfish: boolean;
      gluten: boolean;
      nuts: boolean;
      eggs: boolean;
      fish: boolean;
    } | null;
    customQuestions?: Array<{
      __typename?: "CustomQuestionType";
      id?: number | null;
      answer?: string | null;
      question: string;
    } | null> | null;
  };
};

export type ClientGoalFragmentFragment = {
  __typename?: "ClientType";
  id: number;
  name: string;
  email: string;
  pal?: ClientPalEnum | null;
  age?: number | null;
  birthday?: string | null;
  startWeight?: number | null;
  height?: number | null;
  gender?: GenderEnum | null;
  weightGoal?: number | null;
  measuringSystem?: MeasuringSystemEnum | null;
  progress: Array<{
    __typename?: "ClientProgressType";
    id: number;
    date?: string | null;
    weight?: number | null;
  }>;
  integrations?: {
    __typename?: "ClientIntegrationType";
    healthConnected: boolean;
  } | null;
  steps: Array<{
    __typename?: "ClientStepType";
    id: number;
    steps: number;
    date: string;
  }>;
  habits: Array<{
    __typename?: "ClientHabitType";
    id: number;
    type?: ClientHabitEnum | null;
    goal?: number | null;
  }>;
};

export type GetClientGoalQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientGoalQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    email: string;
    pal?: ClientPalEnum | null;
    age?: number | null;
    birthday?: string | null;
    startWeight?: number | null;
    height?: number | null;
    gender?: GenderEnum | null;
    weightGoal?: number | null;
    measuringSystem?: MeasuringSystemEnum | null;
    progress: Array<{
      __typename?: "ClientProgressType";
      id: number;
      date?: string | null;
      weight?: number | null;
    }>;
    integrations?: {
      __typename?: "ClientIntegrationType";
      healthConnected: boolean;
    } | null;
    steps: Array<{
      __typename?: "ClientStepType";
      id: number;
      steps: number;
      date: string;
    }>;
    habits: Array<{
      __typename?: "ClientHabitType";
      id: number;
      type?: ClientHabitEnum | null;
      goal?: number | null;
    }>;
  };
};

export type UpdateClientGoalMutationVariables = Exact<{
  id: Scalars["Int"];
  input: ClientGoalInputType;
}>;

export type UpdateClientGoalMutation = {
  __typename?: "Mutation";
  clientGoal: {
    __typename?: "ClientType";
    id: number;
    name: string;
    email: string;
    pal?: ClientPalEnum | null;
    age?: number | null;
    birthday?: string | null;
    startWeight?: number | null;
    height?: number | null;
    gender?: GenderEnum | null;
    weightGoal?: number | null;
    measuringSystem?: MeasuringSystemEnum | null;
    progress: Array<{
      __typename?: "ClientProgressType";
      id: number;
      date?: string | null;
      weight?: number | null;
    }>;
    integrations?: {
      __typename?: "ClientIntegrationType";
      healthConnected: boolean;
    } | null;
    steps: Array<{
      __typename?: "ClientStepType";
      id: number;
      steps: number;
      date: string;
    }>;
    habits: Array<{
      __typename?: "ClientHabitType";
      id: number;
      type?: ClientHabitEnum | null;
      goal?: number | null;
    }>;
  };
};

export type GetClientSavedWorkoutsQueryVariables = Exact<{
  id: Scalars["Int"];
  from: Scalars["String"];
  to: Scalars["String"];
}>;

export type GetClientSavedWorkoutsQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    startDate?: string | null;
    endDate?: string | null;
    workoutsPerWeek?: number | null;
    workoutPlans: Array<{ __typename?: "WorkoutPlanType"; id: number }>;
  };
  savedWorkouts: Array<{
    __typename?: "SavedWorkoutType";
    id: number;
    date: string;
    comment?: string | null;
    time?: string | null;
    day: {
      __typename?: "WorkoutDayType";
      id: number;
      name?: string | null;
      workoutPlan?: { __typename?: "WorkoutPlanType"; name: string } | null;
    };
  }>;
};

export type GetClientWorkoutQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientWorkoutQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    workoutsPerWeek?: number | null;
    workoutLocation?: WorkoutLocationEnum | null;
    workoutExperience?: string | null;
    workoutExperienceLevel?: WorkoutExperienceLevelEnum | null;
    workoutPreference?: string | null;
    injuries?: string | null;
  };
};

export type GetIngredientsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInputType>;
  orderBy?: InputMaybe<IngredientOrderByInputType>;
  filters?: InputMaybe<MealProductFilterInputType>;
}>;

export type GetIngredientsQuery = {
  __typename?: "Query";
  getIngredients: {
    __typename?: "IngredientTypePagination";
    total: number;
    data: Array<{
      __typename?: "IngredientType";
      id: number;
      name?: string | null;
      brand?: string | null;
      kcal?: number | null;
      carbohydrates?: number | null;
      fat?: number | null;
      protein?: number | null;
      units: Array<{
        __typename?: "MealIngredientUnitType";
        id: number;
        name?: string | null;
        weight?: number | null;
      }>;
    }>;
  };
};

export type PresignedRequestMutationVariables = Exact<{
  contentType: Scalars["String"];
}>;

export type PresignedRequestMutation = {
  __typename?: "Mutation";
  presignedRequest: {
    __typename?: "PresignedRequestType";
    filename: string;
    url: string;
    s3Url: string;
  };
};

export type AddIngredientToRecipeMutationVariables = Exact<{
  input: RecipeIngredientInputType;
}>;

export type AddIngredientToRecipeMutation = {
  __typename?: "Mutation";
  addIngredientToRecipe: { __typename?: "RecipeIngredientType"; id: number };
};

export type GetRecipeQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetRecipeQuery = {
  __typename?: "Query";
  recipe: {
    __typename?: "RecipeType";
    id: number;
    name?: string | null;
    instructions?: string | null;
    image?: string | null;
    locale?: LocaleEnum | null;
    lenusMacroSplit?: LenusMacroSplitEnum | null;
    meta?: {
      __typename?: "RecipeMetaType";
      lactose: boolean;
      gluten: boolean;
      nuts: boolean;
      eggs: boolean;
      pig: boolean;
      shellfish: boolean;
      fish: boolean;
      isVegetarian: boolean;
      isPescetarian: boolean;
      isVegan: boolean;
    } | null;
    ingredients: Array<{
      __typename?: "RecipeIngredientType";
      id: number;
      order?: number | null;
      amount?: number | null;
      unitAmount?: number | null;
      unit?: {
        __typename?: "MealIngredientUnitType";
        id: number;
        name?: string | null;
        weight?: number | null;
      } | null;
      ingredient?: {
        __typename?: "IngredientType";
        id: number;
        name?: string | null;
        brand?: string | null;
        kcal?: number | null;
        protein?: number | null;
        carbohydrates?: number | null;
        fat?: number | null;
        units: Array<{
          __typename?: "MealIngredientUnitType";
          id: number;
          name?: string | null;
          weight?: number | null;
        }>;
      } | null;
    }>;
  };
};

export type ReorderRecipeIngredientMutationVariables = Exact<{
  recipeIngredientId: Scalars["Int"];
  order?: InputMaybe<Scalars["Int"]>;
}>;

export type ReorderRecipeIngredientMutation = {
  __typename?: "Mutation";
  reorderRecipeIngredient: { __typename?: "RecipeIngredientType"; id: number };
};

export type RemoveRecipeIngredientMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type RemoveRecipeIngredientMutation = {
  __typename?: "Mutation";
  removeRecipeIngredient?: boolean | null;
};

export type UpdateRecipeImageMutationVariables = Exact<{
  recipeId: Scalars["Int"];
  input: UpdateRecipeImageInputType;
}>;

export type UpdateRecipeImageMutation = {
  __typename?: "Mutation";
  updateRecipeImage: { __typename?: "RecipeType"; id: number };
};

export type UpdateRecipeMutationVariables = Exact<{
  id: Scalars["Int"];
  input: UpdateRecipeInputType;
}>;

export type UpdateRecipeMutation = {
  __typename?: "Mutation";
  updateRecipe: { __typename?: "RecipeType"; id: number };
};

export type GetApiKeysQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetApiKeysQuery = {
  __typename?: "Query";
  apiKeys: {
    __typename?: "ApiKeyTypePagination";
    data: Array<{ __typename?: "ApiKeyType"; id: number; name: string; key: string }>;
  };
};

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInputType;
}>;

export type CreateApiKeyMutation = {
  __typename?: "Mutation";
  createApiKey: { __typename?: "ApiKeyType"; id: number };
};

export type AddExerciseToWorkoutDayMutationVariables = Exact<{
  id: Scalars["Int"];
  input: AddExerciseToWorkoutDayInputType;
}>;

export type AddExerciseToWorkoutDayMutation = {
  __typename?: "Mutation";
  addExerciseToWorkoutDay: { __typename?: "WorkoutType"; id: number };
};

export type MoveWorkoutMutationVariables = Exact<{
  input: MoveWorkoutInputType;
}>;

export type MoveWorkoutMutation = {
  __typename?: "Mutation";
  moveWorkout: { __typename?: "WorkoutType"; id: number };
};

export type DeleteWorkoutMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteWorkoutMutation = {
  __typename?: "Mutation";
  deleteWorkout?: boolean | null;
};

export type DeleteApiKeyMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteApiKeyMutation = {
  __typename?: "Mutation";
  deleteApiKey?: boolean | null;
};

export type UpdateRecipeIngredientMutationVariables = Exact<{
  id: Scalars["Int"];
  input: UpdateRecipeIngredientInputType;
}>;

export type UpdateRecipeIngredientMutation = {
  __typename?: "Mutation";
  updateRecipeIngredient: { __typename?: "RecipeIngredientType"; id: number };
};

export type ReorderWorkoutMutationVariables = Exact<{
  workoutDayId: Scalars["Int"];
  input: ReorderWorkoutInputType;
}>;

export type ReorderWorkoutMutation = {
  __typename?: "Mutation";
  reorderWorkout: { __typename?: "WorkoutType"; id: number };
};

export type UpdateWorkoutMutationVariables = Exact<{
  id: Scalars["Int"];
  input: UpdateWorkoutInputType;
}>;

export type UpdateWorkoutMutation = {
  __typename?: "Mutation";
  updateWorkout: {
    __typename?: "WorkoutType";
    id: number;
    reps?: string | null;
    sets?: string | null;
    rest?: string | null;
    time?: string | null;
    description?: string | null;
  };
};

export type CreateWorkoutDayMutationVariables = Exact<{
  id: Scalars["Int"];
  input: WorkoutDayInputType;
}>;

export type CreateWorkoutDayMutation = {
  __typename?: "Mutation";
  createWorkoutDay: {
    __typename?: "WorkoutDayType";
    id: number;
    name?: string | null;
    exercises: Array<{
      __typename?: "WorkoutType";
      id: number;
      order?: number | null;
      reps?: string | null;
      sets?: string | null;
      rest?: string | null;
      time?: string | null;
      title?: string | null;
      description?: string | null;
      parentId?: number | null;
      exercise?: {
        __typename?: "ExerciseType";
        id: number;
        title: string;
        image?: string | null;
        video?: string | null;
        isLenusExercise: boolean;
        type?: ExerciseTypeEnum | null;
      } | null;
      children?: Array<{
        __typename?: "WorkoutType";
        id: number;
        order?: number | null;
        reps?: string | null;
        sets?: string | null;
        rest?: string | null;
        time?: string | null;
        title?: string | null;
        description?: string | null;
        parentId?: number | null;
        exercise?: {
          __typename?: "ExerciseType";
          id: number;
          title: string;
          image?: string | null;
          video?: string | null;
          isLenusExercise: boolean;
          type?: ExerciseTypeEnum | null;
        } | null;
      } | null> | null;
    } | null>;
  };
};

export type DeleteWorkoutDayMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteWorkoutDayMutation = {
  __typename?: "Mutation";
  deleteWorkoutDay?: boolean | null;
};

export type DuplicateWorkoutDayMutationVariables = Exact<{
  id: Scalars["Int"];
  input: WorkoutDayInputType;
}>;

export type DuplicateWorkoutDayMutation = {
  __typename?: "Mutation";
  duplicateWorkoutDay: {
    __typename?: "WorkoutDayType";
    id: number;
    name?: string | null;
    exercises: Array<{
      __typename?: "WorkoutType";
      id: number;
      order?: number | null;
      reps?: string | null;
      sets?: string | null;
      rest?: string | null;
      time?: string | null;
      title?: string | null;
      description?: string | null;
      parentId?: number | null;
      exercise?: {
        __typename?: "ExerciseType";
        id: number;
        title: string;
        image?: string | null;
        video?: string | null;
        isLenusExercise: boolean;
        type?: ExerciseTypeEnum | null;
      } | null;
      children?: Array<{
        __typename?: "WorkoutType";
        id: number;
        order?: number | null;
        reps?: string | null;
        sets?: string | null;
        rest?: string | null;
        time?: string | null;
        title?: string | null;
        description?: string | null;
        parentId?: number | null;
        exercise?: {
          __typename?: "ExerciseType";
          id: number;
          title: string;
          image?: string | null;
          video?: string | null;
          isLenusExercise: boolean;
          type?: ExerciseTypeEnum | null;
        } | null;
      } | null> | null;
    } | null>;
  };
};

export type UpdateWorkoutDayMutationVariables = Exact<{
  id: Scalars["Int"];
  input: UpdateWorkoutDayInputType;
}>;

export type UpdateWorkoutDayMutation = {
  __typename?: "Mutation";
  updateWorkoutDay: { __typename?: "WorkoutDayType"; id: number; name?: string | null };
};

export type ActivateClientMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ActivateClientMutation = {
  __typename?: "Mutation";
  activateClient: { __typename?: "ClientType"; id: number };
};

export type GetClientDietPreferenceQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientDietPreferenceQuery = {
  __typename?: "Query";
  client: {
    __typename?: "ClientType";
    id: number;
    name: string;
    photo?: string | null;
    locale?: LocaleEnum | null;
    startWeight?: number | null;
    weightGoal?: number | null;
    measuringSystem?: MeasuringSystemEnum | null;
    birthday?: string | null;
    age?: number | null;
    gender?: GenderEnum | null;
    height?: number | null;
    pal?: ClientPalEnum | null;
    numberOfMeals?: number | null;
    clientFoodPreferences?: {
      __typename?: "ClientFoodPreferencesType";
      isPescetarian: boolean;
      isVegan: boolean;
      isVegetarian: boolean;
      lactose: boolean;
      pig: boolean;
      shellfish: boolean;
      gluten: boolean;
      nuts: boolean;
      eggs: boolean;
      fish: boolean;
    } | null;
  };
};

export const WorkoutTypeFragmentFragmentDoc = gql`
  fragment WorkoutTypeFragment on WorkoutType {
    id
    exercise {
      id
      title
      image
    }
    title
    rest
    reps
    sets
    time
    tracking {
      id
      weight
      reps
      date
    }
  }
`;
export const ClientListFragmentFragmentDoc = gql`
  fragment clientListFragment on ClientType {
    id
    name
    email
    activated
    startDate
    endDate
    mealPlanSchedule
    workoutPlanSchedule
    mealPlans {
      id
      title
    }
    workoutPlans {
      id
      name
    }
    unresolvedTasks {
      id
      date
      event {
        id
        name
        title
      }
    }
    reminders {
      id
      title
      resolved
      dueDate
    }
    tags
    totalWeeks
    weekNumber
    lastCheckIn(format: "c")
    startDate
    queue {
      id
      datakey
      date
    }
    unreadMessagesCount
    hasCreatedPassword
    coach {
      id
    }
    locale
    photo
    paymentConfiguration {
      id
      currency
      upfrontFee
      recurringFee
      datakey
      months
      url
      status
    }
  }
`;
export const ClientPaymentConfigurationFragmentFragmentDoc = gql`
  fragment ClientPaymentConfigurationFragment on ClientType {
    paymentConfiguration {
      id
      createdAt
      endDate
      months
      isCharged
      terms
      status
      recurringFee
      upfrontFee
      currency
      url
      subscription {
        id
        pausedUntil
        unsubscribedDate
        upcomingInvoiceDueDate
      }
    }
  }
`;
export const MealPlanTemplateFragmentFragmentDoc = gql`
  fragment MealPlanTemplateFragment on MealPlanType {
    id
    title
    description
    migratedLenusOwnedId
    totals {
      numberOfMeals
    }
  }
`;
export const ClientFragmentFragmentDoc = gql`
  fragment clientFragment on ClientType {
    id
    photo
    name
    email
    locale
    isDemo
    phone
    tags
    startDate
    endDate
    checkInDay
    checkInFrequency
    mealPlanSchedule
    workoutPlanSchedule
    note
    gender
    birthday
    age
    measuringSystem
    startWeight
    height
    feet
    inches
    pal
    workoutsPerWeek
    workoutLocation
    workoutExperience
    workoutExperienceLevel
    workoutPreference
    injuries
    hideCalories
    numberOfMeals
    dietStyle
    clientFoodPreferences {
      isPescetarian
      isVegan
      isVegetarian
      lactose
      pig
      lactose
      shellfish
      gluten
      nuts
      eggs
      fish
    }
    motivation
    lifestyle
    other
    customQuestions {
      id
      answer
      question
    }
  }
`;
export const WorkoutSelectionFragmentDoc = gql`
  fragment WorkoutSelection on WorkoutType {
    id
    order
    reps
    sets
    rest
    time
    title
    description
    parentId
    exercise {
      id
      title
      image
      video
      isLenusExercise
      type
    }
    children {
      id
      order
      reps
      sets
      rest
      time
      title
      description
      parentId
      exercise {
        id
        title
        image
        video
        isLenusExercise
        type
      }
    }
  }
`;
export const ClientGoalFragmentFragmentDoc = gql`
  fragment clientGoalFragment on ClientType {
    id
    name
    email
    pal
    age
    birthday
    startWeight
    height
    gender
    weightGoal
    measuringSystem
    progress {
      id
      date
      weight
    }
    integrations {
      healthConnected
    }
    steps {
      id
      steps
      date
    }
    habits {
      id
      type
      goal
    }
  }
`;
export const GetClientContainerDocument = gql`
  query GetClientContainer($id: Int!) {
    client(id: $id) {
      id
      name
      photo
      tags
      hasCreatedPassword
      answeredQuestionnaire
      url
      coach {
        id
      }
    }
  }
`;

/**
 * __useGetClientContainerQuery__
 *
 * To run a query within a React component, call `useGetClientContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientContainerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientContainerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientContainerQuery,
    GetClientContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientContainerQuery, GetClientContainerQueryVariables>(
    GetClientContainerDocument,
    options,
  );
}
export function useGetClientContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientContainerQuery,
    GetClientContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientContainerQuery, GetClientContainerQueryVariables>(
    GetClientContainerDocument,
    options,
  );
}
export type GetClientContainerQueryHookResult = ReturnType<
  typeof useGetClientContainerQuery
>;
export type GetClientContainerLazyQueryHookResult = ReturnType<
  typeof useGetClientContainerLazyQuery
>;
export type GetClientContainerQueryResult = Apollo.QueryResult<
  GetClientContainerQuery,
  GetClientContainerQueryVariables
>;
export const GetClientModalSubscriptionDocument = gql`
  query getClientModalSubscription($clientId: Int!) {
    client(id: $clientId) {
      id
      name
      email
      locale
      coach {
        id
        isConnectedToStripeConnect
        stripeConnectUrl
      }
      ...ClientPaymentConfigurationFragment
    }
  }
  ${ClientPaymentConfigurationFragmentFragmentDoc}
`;

/**
 * __useGetClientModalSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetClientModalSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientModalSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientModalSubscriptionQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientModalSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientModalSubscriptionQuery,
    GetClientModalSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientModalSubscriptionQuery,
    GetClientModalSubscriptionQueryVariables
  >(GetClientModalSubscriptionDocument, options);
}
export function useGetClientModalSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientModalSubscriptionQuery,
    GetClientModalSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientModalSubscriptionQuery,
    GetClientModalSubscriptionQueryVariables
  >(GetClientModalSubscriptionDocument, options);
}
export type GetClientModalSubscriptionQueryHookResult = ReturnType<
  typeof useGetClientModalSubscriptionQuery
>;
export type GetClientModalSubscriptionLazyQueryHookResult = ReturnType<
  typeof useGetClientModalSubscriptionLazyQuery
>;
export type GetClientModalSubscriptionQueryResult = Apollo.QueryResult<
  GetClientModalSubscriptionQuery,
  GetClientModalSubscriptionQueryVariables
>;
export const GetClientPaymentConfigurationDocument = gql`
  query getClientPaymentConfiguration($clientId: Int!) {
    client(id: $clientId) {
      id
      ...ClientPaymentConfigurationFragment
    }
  }
  ${ClientPaymentConfigurationFragmentFragmentDoc}
`;

/**
 * __useGetClientPaymentConfigurationQuery__
 *
 * To run a query within a React component, call `useGetClientPaymentConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientPaymentConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientPaymentConfigurationQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientPaymentConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientPaymentConfigurationQuery,
    GetClientPaymentConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientPaymentConfigurationQuery,
    GetClientPaymentConfigurationQueryVariables
  >(GetClientPaymentConfigurationDocument, options);
}
export function useGetClientPaymentConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientPaymentConfigurationQuery,
    GetClientPaymentConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientPaymentConfigurationQuery,
    GetClientPaymentConfigurationQueryVariables
  >(GetClientPaymentConfigurationDocument, options);
}
export type GetClientPaymentConfigurationQueryHookResult = ReturnType<
  typeof useGetClientPaymentConfigurationQuery
>;
export type GetClientPaymentConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetClientPaymentConfigurationLazyQuery
>;
export type GetClientPaymentConfigurationQueryResult = Apollo.QueryResult<
  GetClientPaymentConfigurationQuery,
  GetClientPaymentConfigurationQueryVariables
>;
export const GetMealTemplateDocument = gql`
  query GetMealTemplate($id: Int!) {
    mealPlanTemplate(id: $id) {
      ...MealPlanTemplateFragment
    }
  }
  ${MealPlanTemplateFragmentFragmentDoc}
`;

/**
 * __useGetMealTemplateQuery__
 *
 * To run a query within a React component, call `useGetMealTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMealTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMealTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMealTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMealTemplateQuery,
    GetMealTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMealTemplateQuery, GetMealTemplateQueryVariables>(
    GetMealTemplateDocument,
    options,
  );
}
export function useGetMealTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMealTemplateQuery,
    GetMealTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMealTemplateQuery, GetMealTemplateQueryVariables>(
    GetMealTemplateDocument,
    options,
  );
}
export type GetMealTemplateQueryHookResult = ReturnType<typeof useGetMealTemplateQuery>;
export type GetMealTemplateLazyQueryHookResult = ReturnType<
  typeof useGetMealTemplateLazyQuery
>;
export type GetMealTemplateQueryResult = Apollo.QueryResult<
  GetMealTemplateQuery,
  GetMealTemplateQueryVariables
>;
export const GetClientInvitationModalDocument = gql`
  query GetClientInvitationModal($clientId: Int!) {
    client(id: $clientId) {
      id
      name
      email
      locale
    }
  }
`;

/**
 * __useGetClientInvitationModalQuery__
 *
 * To run a query within a React component, call `useGetClientInvitationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientInvitationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientInvitationModalQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientInvitationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientInvitationModalQuery,
    GetClientInvitationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientInvitationModalQuery,
    GetClientInvitationModalQueryVariables
  >(GetClientInvitationModalDocument, options);
}
export function useGetClientInvitationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientInvitationModalQuery,
    GetClientInvitationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientInvitationModalQuery,
    GetClientInvitationModalQueryVariables
  >(GetClientInvitationModalDocument, options);
}
export type GetClientInvitationModalQueryHookResult = ReturnType<
  typeof useGetClientInvitationModalQuery
>;
export type GetClientInvitationModalLazyQueryHookResult = ReturnType<
  typeof useGetClientInvitationModalLazyQuery
>;
export type GetClientInvitationModalQueryResult = Apollo.QueryResult<
  GetClientInvitationModalQuery,
  GetClientInvitationModalQueryVariables
>;
export const CreateMealPlanTemplateDocument = gql`
  mutation CreateMealPlanTemplate($input: CreateMealPlanTemplateInputType) {
    createMealPlanTemplate(input: $input) {
      id
      title
      description
      totals {
        numberOfMeals
      }
    }
  }
`;
export type CreateMealPlanTemplateMutationFn = Apollo.MutationFunction<
  CreateMealPlanTemplateMutation,
  CreateMealPlanTemplateMutationVariables
>;

/**
 * __useCreateMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMealPlanTemplateMutation, { data, loading, error }] = useCreateMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMealPlanTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMealPlanTemplateMutation,
    CreateMealPlanTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMealPlanTemplateMutation,
    CreateMealPlanTemplateMutationVariables
  >(CreateMealPlanTemplateDocument, options);
}
export type CreateMealPlanTemplateMutationHookResult = ReturnType<
  typeof useCreateMealPlanTemplateMutation
>;
export type CreateMealPlanTemplateMutationResult =
  Apollo.MutationResult<CreateMealPlanTemplateMutation>;
export type CreateMealPlanTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateMealPlanTemplateMutation,
  CreateMealPlanTemplateMutationVariables
>;
export const GetClientTrackedWorkoutsDocument = gql`
  query GetClientTrackedWorkouts($savedWorkoutId: Int!) {
    savedWorkout(id: $savedWorkoutId) {
      id
      time
      comment
      day {
        id
        name
        exercises {
          ...WorkoutTypeFragment
          children {
            ...WorkoutTypeFragment
          }
        }
      }
    }
  }
  ${WorkoutTypeFragmentFragmentDoc}
`;

/**
 * __useGetClientTrackedWorkoutsQuery__
 *
 * To run a query within a React component, call `useGetClientTrackedWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientTrackedWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientTrackedWorkoutsQuery({
 *   variables: {
 *      savedWorkoutId: // value for 'savedWorkoutId'
 *   },
 * });
 */
export function useGetClientTrackedWorkoutsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientTrackedWorkoutsQuery,
    GetClientTrackedWorkoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientTrackedWorkoutsQuery,
    GetClientTrackedWorkoutsQueryVariables
  >(GetClientTrackedWorkoutsDocument, options);
}
export function useGetClientTrackedWorkoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientTrackedWorkoutsQuery,
    GetClientTrackedWorkoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientTrackedWorkoutsQuery,
    GetClientTrackedWorkoutsQueryVariables
  >(GetClientTrackedWorkoutsDocument, options);
}
export type GetClientTrackedWorkoutsQueryHookResult = ReturnType<
  typeof useGetClientTrackedWorkoutsQuery
>;
export type GetClientTrackedWorkoutsLazyQueryHookResult = ReturnType<
  typeof useGetClientTrackedWorkoutsLazyQuery
>;
export type GetClientTrackedWorkoutsQueryResult = Apollo.QueryResult<
  GetClientTrackedWorkoutsQuery,
  GetClientTrackedWorkoutsQueryVariables
>;
export const GetClientsDocument = gql`
  query GetClients(
    $page: Int!
    $limit: Int!
    $filters: ClientFilterInputType
    $orderBy: ClientsOrderByInputType
  ) {
    me {
      id
      tags
      activeClientCount
      inactiveClientCount
      pendingClientCount
      subscription {
        id
        plan {
          id
          clientCount
        }
      }
    }
    clients(
      pagination: { page: $page, limit: $limit }
      filters: $filters
      orderBy: $orderBy
    ) {
      data {
        ...clientListFragment
      }
    }
  }
  ${ClientListFragmentFragmentDoc}
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>;
export const GetClientFilterCountDocument = gql`
  query GetClientFilterCount($search: String, $tags: [String]) {
    clientFilterCount(search: $search, tags: $tags) {
      active
      activated
      ending
      updatePlans
      missingCheckIn
      checkIn
      unreadMessage
      noContact
      paymentFailed
      dueReminders
      futureReminders
      requestedUnsubscribe
      pending
      createPlans
      paymentPending
      invitePending
      questionnairePending
      readyForActivation
    }
  }
`;

/**
 * __useGetClientFilterCountQuery__
 *
 * To run a query within a React component, call `useGetClientFilterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFilterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientFilterCountQuery({
 *   variables: {
 *      search: // value for 'search'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetClientFilterCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientFilterCountQuery,
    GetClientFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientFilterCountQuery, GetClientFilterCountQueryVariables>(
    GetClientFilterCountDocument,
    options,
  );
}
export function useGetClientFilterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientFilterCountQuery,
    GetClientFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientFilterCountQuery,
    GetClientFilterCountQueryVariables
  >(GetClientFilterCountDocument, options);
}
export type GetClientFilterCountQueryHookResult = ReturnType<
  typeof useGetClientFilterCountQuery
>;
export type GetClientFilterCountLazyQueryHookResult = ReturnType<
  typeof useGetClientFilterCountLazyQuery
>;
export type GetClientFilterCountQueryResult = Apollo.QueryResult<
  GetClientFilterCountQuery,
  GetClientFilterCountQueryVariables
>;
export const ChangeClientActiveStateDocument = gql`
  mutation ChangeClientActiveState($clientId: Int!, $input: ClientActiveStateInputType!) {
    changeClientActiveState(clientId: $clientId, input: $input) {
      ...clientListFragment
    }
  }
  ${ClientListFragmentFragmentDoc}
`;
export type ChangeClientActiveStateMutationFn = Apollo.MutationFunction<
  ChangeClientActiveStateMutation,
  ChangeClientActiveStateMutationVariables
>;

/**
 * __useChangeClientActiveStateMutation__
 *
 * To run a mutation, you first call `useChangeClientActiveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeClientActiveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeClientActiveStateMutation, { data, loading, error }] = useChangeClientActiveStateMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeClientActiveStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeClientActiveStateMutation,
    ChangeClientActiveStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeClientActiveStateMutation,
    ChangeClientActiveStateMutationVariables
  >(ChangeClientActiveStateDocument, options);
}
export type ChangeClientActiveStateMutationHookResult = ReturnType<
  typeof useChangeClientActiveStateMutation
>;
export type ChangeClientActiveStateMutationResult =
  Apollo.MutationResult<ChangeClientActiveStateMutation>;
export type ChangeClientActiveStateMutationOptions = Apollo.BaseMutationOptions<
  ChangeClientActiveStateMutation,
  ChangeClientActiveStateMutationVariables
>;
export const DeleteClientsDocument = gql`
  mutation DeleteClients($ids: [Int]!) {
    deleteClients(ids: $ids)
  }
`;
export type DeleteClientsMutationFn = Apollo.MutationFunction<
  DeleteClientsMutation,
  DeleteClientsMutationVariables
>;

/**
 * __useDeleteClientsMutation__
 *
 * To run a mutation, you first call `useDeleteClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientsMutation, { data, loading, error }] = useDeleteClientsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientsMutation,
    DeleteClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteClientsMutation, DeleteClientsMutationVariables>(
    DeleteClientsDocument,
    options,
  );
}
export type DeleteClientsMutationHookResult = ReturnType<typeof useDeleteClientsMutation>;
export type DeleteClientsMutationResult = Apollo.MutationResult<DeleteClientsMutation>;
export type DeleteClientsMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientsMutation,
  DeleteClientsMutationVariables
>;
export const UpdateClientTagDocument = gql`
  mutation UpdateClientTag($clientId: Int!, $input: ClientSetupInputType!) {
    updateClientInfo(clientId: $clientId, input: $input) {
      id
      tags
    }
  }
`;
export type UpdateClientTagMutationFn = Apollo.MutationFunction<
  UpdateClientTagMutation,
  UpdateClientTagMutationVariables
>;

/**
 * __useUpdateClientTagMutation__
 *
 * To run a mutation, you first call `useUpdateClientTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientTagMutation, { data, loading, error }] = useUpdateClientTagMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientTagMutation,
    UpdateClientTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientTagMutation, UpdateClientTagMutationVariables>(
    UpdateClientTagDocument,
    options,
  );
}
export type UpdateClientTagMutationHookResult = ReturnType<
  typeof useUpdateClientTagMutation
>;
export type UpdateClientTagMutationResult =
  Apollo.MutationResult<UpdateClientTagMutation>;
export type UpdateClientTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientTagMutation,
  UpdateClientTagMutationVariables
>;
export const ResolveReminderDocument = gql`
  mutation ResolveReminder($reminderId: Int!) {
    ignoreClientReminder(clientReminderId: $reminderId) {
      id
      title
      dueDate
      resolved
    }
  }
`;
export type ResolveReminderMutationFn = Apollo.MutationFunction<
  ResolveReminderMutation,
  ResolveReminderMutationVariables
>;

/**
 * __useResolveReminderMutation__
 *
 * To run a mutation, you first call `useResolveReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveReminderMutation, { data, loading, error }] = useResolveReminderMutation({
 *   variables: {
 *      reminderId: // value for 'reminderId'
 *   },
 * });
 */
export function useResolveReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveReminderMutation,
    ResolveReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResolveReminderMutation, ResolveReminderMutationVariables>(
    ResolveReminderDocument,
    options,
  );
}
export type ResolveReminderMutationHookResult = ReturnType<
  typeof useResolveReminderMutation
>;
export type ResolveReminderMutationResult =
  Apollo.MutationResult<ResolveReminderMutation>;
export type ResolveReminderMutationOptions = Apollo.BaseMutationOptions<
  ResolveReminderMutation,
  ResolveReminderMutationVariables
>;
export const GetClientSubscriptionDocument = gql`
  query getClientSubscription($id: Int!) {
    client(id: $id) {
      id
      name
      isDemo
      coach {
        id
        isConnectedToStripeConnect
        stripeConnectUrl
      }
      ...ClientPaymentConfigurationFragment
      payments {
        id
        date
        amount
        chargeId
        type
        currency
        reason
      }
    }
  }
  ${ClientPaymentConfigurationFragmentFragmentDoc}
`;

/**
 * __useGetClientSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetClientSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientSubscriptionQuery,
    GetClientSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientSubscriptionQuery, GetClientSubscriptionQueryVariables>(
    GetClientSubscriptionDocument,
    options,
  );
}
export function useGetClientSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSubscriptionQuery,
    GetClientSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientSubscriptionQuery,
    GetClientSubscriptionQueryVariables
  >(GetClientSubscriptionDocument, options);
}
export type GetClientSubscriptionQueryHookResult = ReturnType<
  typeof useGetClientSubscriptionQuery
>;
export type GetClientSubscriptionLazyQueryHookResult = ReturnType<
  typeof useGetClientSubscriptionLazyQuery
>;
export type GetClientSubscriptionQueryResult = Apollo.QueryResult<
  GetClientSubscriptionQuery,
  GetClientSubscriptionQueryVariables
>;
export const DeleteLatestPendingPaymentDocument = gql`
  mutation DeleteLatestPendingPayment($clientId: Int!) {
    deleteLatestPendingPayment(clientId: $clientId)
  }
`;
export type DeleteLatestPendingPaymentMutationFn = Apollo.MutationFunction<
  DeleteLatestPendingPaymentMutation,
  DeleteLatestPendingPaymentMutationVariables
>;

/**
 * __useDeleteLatestPendingPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteLatestPendingPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLatestPendingPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLatestPendingPaymentMutation, { data, loading, error }] = useDeleteLatestPendingPaymentMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useDeleteLatestPendingPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLatestPendingPaymentMutation,
    DeleteLatestPendingPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLatestPendingPaymentMutation,
    DeleteLatestPendingPaymentMutationVariables
  >(DeleteLatestPendingPaymentDocument, options);
}
export type DeleteLatestPendingPaymentMutationHookResult = ReturnType<
  typeof useDeleteLatestPendingPaymentMutation
>;
export type DeleteLatestPendingPaymentMutationResult =
  Apollo.MutationResult<DeleteLatestPendingPaymentMutation>;
export type DeleteLatestPendingPaymentMutationOptions = Apollo.BaseMutationOptions<
  DeleteLatestPendingPaymentMutation,
  DeleteLatestPendingPaymentMutationVariables
>;
export const ResolveTaskDocument = gql`
  mutation ResolveTask($taskId: Int!) {
    ignoreClientStatus(clientStatusId: $taskId) {
      id
    }
  }
`;
export type ResolveTaskMutationFn = Apollo.MutationFunction<
  ResolveTaskMutation,
  ResolveTaskMutationVariables
>;

/**
 * __useResolveTaskMutation__
 *
 * To run a mutation, you first call `useResolveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveTaskMutation, { data, loading, error }] = useResolveTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useResolveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveTaskMutation,
    ResolveTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResolveTaskMutation, ResolveTaskMutationVariables>(
    ResolveTaskDocument,
    options,
  );
}
export type ResolveTaskMutationHookResult = ReturnType<typeof useResolveTaskMutation>;
export type ResolveTaskMutationResult = Apollo.MutationResult<ResolveTaskMutation>;
export type ResolveTaskMutationOptions = Apollo.BaseMutationOptions<
  ResolveTaskMutation,
  ResolveTaskMutationVariables
>;
export const GetSubscriptionPlansDocument = gql`
  query GetSubscriptionPlans(
    $filters: PlansFilterInputType!
    $pagination: PaginationInputType!
  ) {
    plans(filters: $filters, pagination: $pagination) {
      data {
        id
        active
        name
        currency
        clientCount
        country
        isTiered
        priceMonth
      }
    }
  }
`;

/**
 * __useGetSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetSubscriptionPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionPlansQuery, GetSubscriptionPlansQueryVariables>(
    GetSubscriptionPlansDocument,
    options,
  );
}
export function useGetSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >(GetSubscriptionPlansDocument, options);
}
export type GetSubscriptionPlansQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansQuery
>;
export type GetSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansLazyQuery
>;
export type GetSubscriptionPlansQueryResult = Apollo.QueryResult<
  GetSubscriptionPlansQuery,
  GetSubscriptionPlansQueryVariables
>;
export const InitiateLenusTransferDocument = gql`
  mutation InitiateLenusTransfer($input: InitiateLenusTransferInputType!) {
    initiateLenusTransfer(input: $input)
  }
`;
export type InitiateLenusTransferMutationFn = Apollo.MutationFunction<
  InitiateLenusTransferMutation,
  InitiateLenusTransferMutationVariables
>;

/**
 * __useInitiateLenusTransferMutation__
 *
 * To run a mutation, you first call `useInitiateLenusTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateLenusTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateLenusTransferMutation, { data, loading, error }] = useInitiateLenusTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateLenusTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateLenusTransferMutation,
    InitiateLenusTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateLenusTransferMutation,
    InitiateLenusTransferMutationVariables
  >(InitiateLenusTransferDocument, options);
}
export type InitiateLenusTransferMutationHookResult = ReturnType<
  typeof useInitiateLenusTransferMutation
>;
export type InitiateLenusTransferMutationResult =
  Apollo.MutationResult<InitiateLenusTransferMutation>;
export type InitiateLenusTransferMutationOptions = Apollo.BaseMutationOptions<
  InitiateLenusTransferMutation,
  InitiateLenusTransferMutationVariables
>;
export const GetClientFoodPreferenceDocument = gql`
  query GetClientFoodPreference($id: Int!) {
    client(id: $id) {
      id
      hideCalories
      gender
      birthday
      age
      height
      startWeight
      weightGoal
      pal
      measuringSystem
      numberOfMeals
      dietStyle
      pal
      clientFoodPreferences {
        isPescetarian
        isVegan
        isVegetarian
        lactose
        pig
        lactose
        shellfish
        gluten
        nuts
        eggs
        fish
        significantLoss
        lostControl
        feelsFat
        uncomfortablyFull
        foodDominates
      }
    }
  }
`;

/**
 * __useGetClientFoodPreferenceQuery__
 *
 * To run a query within a React component, call `useGetClientFoodPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFoodPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientFoodPreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientFoodPreferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientFoodPreferenceQuery,
    GetClientFoodPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientFoodPreferenceQuery,
    GetClientFoodPreferenceQueryVariables
  >(GetClientFoodPreferenceDocument, options);
}
export function useGetClientFoodPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientFoodPreferenceQuery,
    GetClientFoodPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientFoodPreferenceQuery,
    GetClientFoodPreferenceQueryVariables
  >(GetClientFoodPreferenceDocument, options);
}
export type GetClientFoodPreferenceQueryHookResult = ReturnType<
  typeof useGetClientFoodPreferenceQuery
>;
export type GetClientFoodPreferenceLazyQueryHookResult = ReturnType<
  typeof useGetClientFoodPreferenceLazyQuery
>;
export type GetClientFoodPreferenceQueryResult = Apollo.QueryResult<
  GetClientFoodPreferenceQuery,
  GetClientFoodPreferenceQueryVariables
>;
export const GetMealTemplatesDocument = gql`
  query GetMealTemplates(
    $page: Int!
    $limit: Int!
    $filters: MealPlanTemplateFilterInputType
    $orderBy: MealPlanTemplatesOrderByInputType
  ) {
    mealPlanTemplates(
      pagination: { page: $page, limit: $limit }
      filters: $filters
      orderBy: $orderBy
    ) {
      total
      data {
        ...MealPlanTemplateFragment
      }
    }
  }
  ${MealPlanTemplateFragmentFragmentDoc}
`;

/**
 * __useGetMealTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMealTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMealTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMealTemplatesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMealTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMealTemplatesQuery,
    GetMealTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMealTemplatesQuery, GetMealTemplatesQueryVariables>(
    GetMealTemplatesDocument,
    options,
  );
}
export function useGetMealTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMealTemplatesQuery,
    GetMealTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMealTemplatesQuery, GetMealTemplatesQueryVariables>(
    GetMealTemplatesDocument,
    options,
  );
}
export type GetMealTemplatesQueryHookResult = ReturnType<typeof useGetMealTemplatesQuery>;
export type GetMealTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetMealTemplatesLazyQuery
>;
export type GetMealTemplatesQueryResult = Apollo.QueryResult<
  GetMealTemplatesQuery,
  GetMealTemplatesQueryVariables
>;
export const DeleteMealTemplateDocument = gql`
  mutation DeleteMealTemplate($id: Int!) {
    deleteMealPlan(id: $id)
  }
`;
export type DeleteMealTemplateMutationFn = Apollo.MutationFunction<
  DeleteMealTemplateMutation,
  DeleteMealTemplateMutationVariables
>;

/**
 * __useDeleteMealTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealTemplateMutation, { data, loading, error }] = useDeleteMealTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMealTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMealTemplateMutation,
    DeleteMealTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMealTemplateMutation,
    DeleteMealTemplateMutationVariables
  >(DeleteMealTemplateDocument, options);
}
export type DeleteMealTemplateMutationHookResult = ReturnType<
  typeof useDeleteMealTemplateMutation
>;
export type DeleteMealTemplateMutationResult =
  Apollo.MutationResult<DeleteMealTemplateMutation>;
export type DeleteMealTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteMealTemplateMutation,
  DeleteMealTemplateMutationVariables
>;
export const UpdateWorkoutPlanDocument = gql`
  mutation UpdateWorkoutPlan($id: Int!, $input: UpdateWorkoutPlanInputType!) {
    updateWorkoutPlan(id: $id, input: $input) {
      id
      name
    }
  }
`;
export type UpdateWorkoutPlanMutationFn = Apollo.MutationFunction<
  UpdateWorkoutPlanMutation,
  UpdateWorkoutPlanMutationVariables
>;

/**
 * __useUpdateWorkoutPlanMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutPlanMutation, { data, loading, error }] = useUpdateWorkoutPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkoutPlanMutation,
    UpdateWorkoutPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkoutPlanMutation,
    UpdateWorkoutPlanMutationVariables
  >(UpdateWorkoutPlanDocument, options);
}
export type UpdateWorkoutPlanMutationHookResult = ReturnType<
  typeof useUpdateWorkoutPlanMutation
>;
export type UpdateWorkoutPlanMutationResult =
  Apollo.MutationResult<UpdateWorkoutPlanMutation>;
export type UpdateWorkoutPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkoutPlanMutation,
  UpdateWorkoutPlanMutationVariables
>;
export const SaveWorkoutPlanAsTemplateDocument = gql`
  mutation SaveWorkoutPlanAsTemplate($id: Int!, $input: WorkoutPlanAsTemplateInputType!) {
    saveWorkoutPlanAsTemplate(id: $id, input: $input) {
      id
    }
  }
`;
export type SaveWorkoutPlanAsTemplateMutationFn = Apollo.MutationFunction<
  SaveWorkoutPlanAsTemplateMutation,
  SaveWorkoutPlanAsTemplateMutationVariables
>;

/**
 * __useSaveWorkoutPlanAsTemplateMutation__
 *
 * To run a mutation, you first call `useSaveWorkoutPlanAsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkoutPlanAsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkoutPlanAsTemplateMutation, { data, loading, error }] = useSaveWorkoutPlanAsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWorkoutPlanAsTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveWorkoutPlanAsTemplateMutation,
    SaveWorkoutPlanAsTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveWorkoutPlanAsTemplateMutation,
    SaveWorkoutPlanAsTemplateMutationVariables
  >(SaveWorkoutPlanAsTemplateDocument, options);
}
export type SaveWorkoutPlanAsTemplateMutationHookResult = ReturnType<
  typeof useSaveWorkoutPlanAsTemplateMutation
>;
export type SaveWorkoutPlanAsTemplateMutationResult =
  Apollo.MutationResult<SaveWorkoutPlanAsTemplateMutation>;
export type SaveWorkoutPlanAsTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveWorkoutPlanAsTemplateMutation,
  SaveWorkoutPlanAsTemplateMutationVariables
>;
export const GetWorkoutPlanTemplateDocument = gql`
  query GetWorkoutPlanTemplate($id: Int!) {
    workoutPlan(id: $id) {
      id
      name
      description
      comment
      meta {
        id
        level
        location
        workoutsPerWeek
        gender
      }
      days {
        id
        name
        order
        description
        exercises {
          ...WorkoutSelection
        }
      }
    }
  }
  ${WorkoutSelectionFragmentDoc}
`;

/**
 * __useGetWorkoutPlanTemplateQuery__
 *
 * To run a query within a React component, call `useGetWorkoutPlanTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutPlanTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutPlanTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkoutPlanTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutPlanTemplateQuery,
    GetWorkoutPlanTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutPlanTemplateQuery,
    GetWorkoutPlanTemplateQueryVariables
  >(GetWorkoutPlanTemplateDocument, options);
}
export function useGetWorkoutPlanTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutPlanTemplateQuery,
    GetWorkoutPlanTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutPlanTemplateQuery,
    GetWorkoutPlanTemplateQueryVariables
  >(GetWorkoutPlanTemplateDocument, options);
}
export type GetWorkoutPlanTemplateQueryHookResult = ReturnType<
  typeof useGetWorkoutPlanTemplateQuery
>;
export type GetWorkoutPlanTemplateLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutPlanTemplateLazyQuery
>;
export type GetWorkoutPlanTemplateQueryResult = Apollo.QueryResult<
  GetWorkoutPlanTemplateQuery,
  GetWorkoutPlanTemplateQueryVariables
>;
export const GetClientGeneralDocument = gql`
  query GetClientGeneral($id: Int!) {
    client(id: $id) {
      id
      gender
      startWeight
      weightGoal
      answeredQuestionnaire
      birthday
      isDemo
      locale
      url
      age
      startDate
      checkInDay
      checkInFrequency
      checkInReminderLastSent
      measuringSystem
      endDate
      note
      integrations {
        healthConnected
        mfpConnected
      }
      coach {
        id
        isConnectedToStripeConnect
      }
      unresolvedTasks {
        id
        date
        event {
          id
          name
          title
        }
        resolved
        resolvedBy
      }
      paymentConfiguration {
        id
        status
        url
      }
      clientFoodPreferences {
        significantLoss
        lostControl
        feelsFat
        uncomfortablyFull
        foodDominates
      }
    }
  }
`;

/**
 * __useGetClientGeneralQuery__
 *
 * To run a query within a React component, call `useGetClientGeneralQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientGeneralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientGeneralQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientGeneralQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientGeneralQuery,
    GetClientGeneralQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientGeneralQuery, GetClientGeneralQueryVariables>(
    GetClientGeneralDocument,
    options,
  );
}
export function useGetClientGeneralLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientGeneralQuery,
    GetClientGeneralQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientGeneralQuery, GetClientGeneralQueryVariables>(
    GetClientGeneralDocument,
    options,
  );
}
export type GetClientGeneralQueryHookResult = ReturnType<typeof useGetClientGeneralQuery>;
export type GetClientGeneralLazyQueryHookResult = ReturnType<
  typeof useGetClientGeneralLazyQuery
>;
export type GetClientGeneralQueryResult = Apollo.QueryResult<
  GetClientGeneralQuery,
  GetClientGeneralQueryVariables
>;
export const GetClientStatusesDocument = gql`
  query GetClientStatuses($id: Int!) {
    client(id: $id) {
      id
      unresolvedTasks {
        id
        date
        event {
          id
          name
          title
        }
        resolved
        resolvedBy
      }
    }
  }
`;

/**
 * __useGetClientStatusesQuery__
 *
 * To run a query within a React component, call `useGetClientStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientStatusesQuery,
    GetClientStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientStatusesQuery, GetClientStatusesQueryVariables>(
    GetClientStatusesDocument,
    options,
  );
}
export function useGetClientStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientStatusesQuery,
    GetClientStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientStatusesQuery, GetClientStatusesQueryVariables>(
    GetClientStatusesDocument,
    options,
  );
}
export type GetClientStatusesQueryHookResult = ReturnType<
  typeof useGetClientStatusesQuery
>;
export type GetClientStatusesLazyQueryHookResult = ReturnType<
  typeof useGetClientStatusesLazyQuery
>;
export type GetClientStatusesQueryResult = Apollo.QueryResult<
  GetClientStatusesQuery,
  GetClientStatusesQueryVariables
>;
export const GetClientSettingsDocument = gql`
  query GetClientSettings($id: Int!) {
    client(id: $id) {
      ...clientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`;

/**
 * __useGetClientSettingsQuery__
 *
 * To run a query within a React component, call `useGetClientSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientSettingsQuery,
    GetClientSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientSettingsQuery, GetClientSettingsQueryVariables>(
    GetClientSettingsDocument,
    options,
  );
}
export function useGetClientSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSettingsQuery,
    GetClientSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientSettingsQuery, GetClientSettingsQueryVariables>(
    GetClientSettingsDocument,
    options,
  );
}
export type GetClientSettingsQueryHookResult = ReturnType<
  typeof useGetClientSettingsQuery
>;
export type GetClientSettingsLazyQueryHookResult = ReturnType<
  typeof useGetClientSettingsLazyQuery
>;
export type GetClientSettingsQueryResult = Apollo.QueryResult<
  GetClientSettingsQuery,
  GetClientSettingsQueryVariables
>;
export const UpdateClientSettingsDocument = gql`
  mutation UpdateClientSettings($id: Int!, $input: ClientSetupInputType!) {
    updateClientInfo(clientId: $id, input: $input) {
      ...clientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`;
export type UpdateClientSettingsMutationFn = Apollo.MutationFunction<
  UpdateClientSettingsMutation,
  UpdateClientSettingsMutationVariables
>;

/**
 * __useUpdateClientSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateClientSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSettingsMutation, { data, loading, error }] = useUpdateClientSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientSettingsMutation,
    UpdateClientSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientSettingsMutation,
    UpdateClientSettingsMutationVariables
  >(UpdateClientSettingsDocument, options);
}
export type UpdateClientSettingsMutationHookResult = ReturnType<
  typeof useUpdateClientSettingsMutation
>;
export type UpdateClientSettingsMutationResult =
  Apollo.MutationResult<UpdateClientSettingsMutation>;
export type UpdateClientSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientSettingsMutation,
  UpdateClientSettingsMutationVariables
>;
export const GetClientGoalDocument = gql`
  query getClientGoal($id: Int!) {
    client(id: $id) {
      ...clientGoalFragment
    }
  }
  ${ClientGoalFragmentFragmentDoc}
`;

/**
 * __useGetClientGoalQuery__
 *
 * To run a query within a React component, call `useGetClientGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientGoalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientGoalQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientGoalQuery, GetClientGoalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientGoalQuery, GetClientGoalQueryVariables>(
    GetClientGoalDocument,
    options,
  );
}
export function useGetClientGoalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientGoalQuery,
    GetClientGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientGoalQuery, GetClientGoalQueryVariables>(
    GetClientGoalDocument,
    options,
  );
}
export type GetClientGoalQueryHookResult = ReturnType<typeof useGetClientGoalQuery>;
export type GetClientGoalLazyQueryHookResult = ReturnType<
  typeof useGetClientGoalLazyQuery
>;
export type GetClientGoalQueryResult = Apollo.QueryResult<
  GetClientGoalQuery,
  GetClientGoalQueryVariables
>;
export const UpdateClientGoalDocument = gql`
  mutation UpdateClientGoal($id: Int!, $input: ClientGoalInputType!) {
    clientGoal(clientId: $id, input: $input) {
      ...clientGoalFragment
    }
  }
  ${ClientGoalFragmentFragmentDoc}
`;
export type UpdateClientGoalMutationFn = Apollo.MutationFunction<
  UpdateClientGoalMutation,
  UpdateClientGoalMutationVariables
>;

/**
 * __useUpdateClientGoalMutation__
 *
 * To run a mutation, you first call `useUpdateClientGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientGoalMutation, { data, loading, error }] = useUpdateClientGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientGoalMutation,
    UpdateClientGoalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientGoalMutation, UpdateClientGoalMutationVariables>(
    UpdateClientGoalDocument,
    options,
  );
}
export type UpdateClientGoalMutationHookResult = ReturnType<
  typeof useUpdateClientGoalMutation
>;
export type UpdateClientGoalMutationResult =
  Apollo.MutationResult<UpdateClientGoalMutation>;
export type UpdateClientGoalMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientGoalMutation,
  UpdateClientGoalMutationVariables
>;
export const GetClientSavedWorkoutsDocument = gql`
  query GetClientSavedWorkouts($id: Int!, $from: String!, $to: String!) {
    client(id: $id) {
      id
      name
      startDate
      endDate
      workoutsPerWeek
      workoutPlans {
        id
      }
    }
    savedWorkouts(clientId: $id, period: { from: $from, to: $to }) {
      id
      date
      comment
      time
      day {
        id
        name
        workoutPlan {
          name
        }
      }
    }
  }
`;

/**
 * __useGetClientSavedWorkoutsQuery__
 *
 * To run a query within a React component, call `useGetClientSavedWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSavedWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSavedWorkoutsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetClientSavedWorkoutsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientSavedWorkoutsQuery,
    GetClientSavedWorkoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientSavedWorkoutsQuery,
    GetClientSavedWorkoutsQueryVariables
  >(GetClientSavedWorkoutsDocument, options);
}
export function useGetClientSavedWorkoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientSavedWorkoutsQuery,
    GetClientSavedWorkoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientSavedWorkoutsQuery,
    GetClientSavedWorkoutsQueryVariables
  >(GetClientSavedWorkoutsDocument, options);
}
export type GetClientSavedWorkoutsQueryHookResult = ReturnType<
  typeof useGetClientSavedWorkoutsQuery
>;
export type GetClientSavedWorkoutsLazyQueryHookResult = ReturnType<
  typeof useGetClientSavedWorkoutsLazyQuery
>;
export type GetClientSavedWorkoutsQueryResult = Apollo.QueryResult<
  GetClientSavedWorkoutsQuery,
  GetClientSavedWorkoutsQueryVariables
>;
export const GetClientWorkoutDocument = gql`
  query GetClientWorkout($id: Int!) {
    client(id: $id) {
      id
      workoutsPerWeek
      workoutLocation
      workoutExperience
      workoutExperienceLevel
      workoutPreference
      injuries
    }
  }
`;

/**
 * __useGetClientWorkoutQuery__
 *
 * To run a query within a React component, call `useGetClientWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientWorkoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientWorkoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientWorkoutQuery,
    GetClientWorkoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientWorkoutQuery, GetClientWorkoutQueryVariables>(
    GetClientWorkoutDocument,
    options,
  );
}
export function useGetClientWorkoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientWorkoutQuery,
    GetClientWorkoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientWorkoutQuery, GetClientWorkoutQueryVariables>(
    GetClientWorkoutDocument,
    options,
  );
}
export type GetClientWorkoutQueryHookResult = ReturnType<typeof useGetClientWorkoutQuery>;
export type GetClientWorkoutLazyQueryHookResult = ReturnType<
  typeof useGetClientWorkoutLazyQuery
>;
export type GetClientWorkoutQueryResult = Apollo.QueryResult<
  GetClientWorkoutQuery,
  GetClientWorkoutQueryVariables
>;
export const GetIngredientsDocument = gql`
  query GetIngredients(
    $pagination: PaginationInputType
    $orderBy: IngredientOrderByInputType
    $filters: MealProductFilterInputType
  ) {
    getIngredients(pagination: $pagination, orderBy: $orderBy, filters: $filters) {
      total
      data {
        id
        name
        brand
        kcal
        units {
          id
          name
          weight
        }
        carbohydrates
        fat
        protein
      }
    }
  }
`;

/**
 * __useGetIngredientsQuery__
 *
 * To run a query within a React component, call `useGetIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetIngredientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIngredientsQuery,
    GetIngredientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(
    GetIngredientsDocument,
    options,
  );
}
export function useGetIngredientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIngredientsQuery,
    GetIngredientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(
    GetIngredientsDocument,
    options,
  );
}
export type GetIngredientsQueryHookResult = ReturnType<typeof useGetIngredientsQuery>;
export type GetIngredientsLazyQueryHookResult = ReturnType<
  typeof useGetIngredientsLazyQuery
>;
export type GetIngredientsQueryResult = Apollo.QueryResult<
  GetIngredientsQuery,
  GetIngredientsQueryVariables
>;
export const PresignedRequestDocument = gql`
  mutation PresignedRequest($contentType: String!) {
    presignedRequest(contentType: $contentType) {
      filename
      url
      s3Url
    }
  }
`;
export type PresignedRequestMutationFn = Apollo.MutationFunction<
  PresignedRequestMutation,
  PresignedRequestMutationVariables
>;

/**
 * __usePresignedRequestMutation__
 *
 * To run a mutation, you first call `usePresignedRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePresignedRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [presignedRequestMutation, { data, loading, error }] = usePresignedRequestMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function usePresignedRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PresignedRequestMutation,
    PresignedRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PresignedRequestMutation, PresignedRequestMutationVariables>(
    PresignedRequestDocument,
    options,
  );
}
export type PresignedRequestMutationHookResult = ReturnType<
  typeof usePresignedRequestMutation
>;
export type PresignedRequestMutationResult =
  Apollo.MutationResult<PresignedRequestMutation>;
export type PresignedRequestMutationOptions = Apollo.BaseMutationOptions<
  PresignedRequestMutation,
  PresignedRequestMutationVariables
>;
export const AddIngredientToRecipeDocument = gql`
  mutation AddIngredientToRecipe($input: RecipeIngredientInputType!) {
    addIngredientToRecipe(input: $input) {
      id
    }
  }
`;
export type AddIngredientToRecipeMutationFn = Apollo.MutationFunction<
  AddIngredientToRecipeMutation,
  AddIngredientToRecipeMutationVariables
>;

/**
 * __useAddIngredientToRecipeMutation__
 *
 * To run a mutation, you first call `useAddIngredientToRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIngredientToRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIngredientToRecipeMutation, { data, loading, error }] = useAddIngredientToRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIngredientToRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIngredientToRecipeMutation,
    AddIngredientToRecipeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddIngredientToRecipeMutation,
    AddIngredientToRecipeMutationVariables
  >(AddIngredientToRecipeDocument, options);
}
export type AddIngredientToRecipeMutationHookResult = ReturnType<
  typeof useAddIngredientToRecipeMutation
>;
export type AddIngredientToRecipeMutationResult =
  Apollo.MutationResult<AddIngredientToRecipeMutation>;
export type AddIngredientToRecipeMutationOptions = Apollo.BaseMutationOptions<
  AddIngredientToRecipeMutation,
  AddIngredientToRecipeMutationVariables
>;
export const GetRecipeDocument = gql`
  query getRecipe($id: Int!) {
    recipe(id: $id, locale: null) {
      id
      name
      instructions
      image
      locale
      lenusMacroSplit
      meta {
        lactose
        gluten
        nuts
        eggs
        pig
        shellfish
        fish
        isVegetarian
        isPescetarian
        isVegan
      }
      ingredients {
        id
        order
        amount
        unit {
          id
          name
          weight
        }
        unitAmount
        ingredient {
          id
          name
          brand
          kcal
          units {
            id
            name
            weight
          }
          protein
          carbohydrates
          fat
        }
      }
    }
  }
`;

/**
 * __useGetRecipeQuery__
 *
 * To run a query within a React component, call `useGetRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecipeQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeQuery, GetRecipeQueryVariables>(
    GetRecipeDocument,
    options,
  );
}
export function useGetRecipeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeQuery, GetRecipeQueryVariables>(
    GetRecipeDocument,
    options,
  );
}
export type GetRecipeQueryHookResult = ReturnType<typeof useGetRecipeQuery>;
export type GetRecipeLazyQueryHookResult = ReturnType<typeof useGetRecipeLazyQuery>;
export type GetRecipeQueryResult = Apollo.QueryResult<
  GetRecipeQuery,
  GetRecipeQueryVariables
>;
export const ReorderRecipeIngredientDocument = gql`
  mutation ReorderRecipeIngredient($recipeIngredientId: Int!, $order: Int) {
    reorderRecipeIngredient(recipeIngredientId: $recipeIngredientId, order: $order) {
      id
    }
  }
`;
export type ReorderRecipeIngredientMutationFn = Apollo.MutationFunction<
  ReorderRecipeIngredientMutation,
  ReorderRecipeIngredientMutationVariables
>;

/**
 * __useReorderRecipeIngredientMutation__
 *
 * To run a mutation, you first call `useReorderRecipeIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderRecipeIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderRecipeIngredientMutation, { data, loading, error }] = useReorderRecipeIngredientMutation({
 *   variables: {
 *      recipeIngredientId: // value for 'recipeIngredientId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReorderRecipeIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderRecipeIngredientMutation,
    ReorderRecipeIngredientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderRecipeIngredientMutation,
    ReorderRecipeIngredientMutationVariables
  >(ReorderRecipeIngredientDocument, options);
}
export type ReorderRecipeIngredientMutationHookResult = ReturnType<
  typeof useReorderRecipeIngredientMutation
>;
export type ReorderRecipeIngredientMutationResult =
  Apollo.MutationResult<ReorderRecipeIngredientMutation>;
export type ReorderRecipeIngredientMutationOptions = Apollo.BaseMutationOptions<
  ReorderRecipeIngredientMutation,
  ReorderRecipeIngredientMutationVariables
>;
export const RemoveRecipeIngredientDocument = gql`
  mutation RemoveRecipeIngredient($id: Int!) {
    removeRecipeIngredient(id: $id)
  }
`;
export type RemoveRecipeIngredientMutationFn = Apollo.MutationFunction<
  RemoveRecipeIngredientMutation,
  RemoveRecipeIngredientMutationVariables
>;

/**
 * __useRemoveRecipeIngredientMutation__
 *
 * To run a mutation, you first call `useRemoveRecipeIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecipeIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecipeIngredientMutation, { data, loading, error }] = useRemoveRecipeIngredientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRecipeIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRecipeIngredientMutation,
    RemoveRecipeIngredientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRecipeIngredientMutation,
    RemoveRecipeIngredientMutationVariables
  >(RemoveRecipeIngredientDocument, options);
}
export type RemoveRecipeIngredientMutationHookResult = ReturnType<
  typeof useRemoveRecipeIngredientMutation
>;
export type RemoveRecipeIngredientMutationResult =
  Apollo.MutationResult<RemoveRecipeIngredientMutation>;
export type RemoveRecipeIngredientMutationOptions = Apollo.BaseMutationOptions<
  RemoveRecipeIngredientMutation,
  RemoveRecipeIngredientMutationVariables
>;
export const UpdateRecipeImageDocument = gql`
  mutation UpdateRecipeImage($recipeId: Int!, $input: UpdateRecipeImageInputType!) {
    updateRecipeImage(recipeId: $recipeId, input: $input) {
      id
    }
  }
`;
export type UpdateRecipeImageMutationFn = Apollo.MutationFunction<
  UpdateRecipeImageMutation,
  UpdateRecipeImageMutationVariables
>;

/**
 * __useUpdateRecipeImageMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeImageMutation, { data, loading, error }] = useUpdateRecipeImageMutation({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecipeImageMutation,
    UpdateRecipeImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecipeImageMutation,
    UpdateRecipeImageMutationVariables
  >(UpdateRecipeImageDocument, options);
}
export type UpdateRecipeImageMutationHookResult = ReturnType<
  typeof useUpdateRecipeImageMutation
>;
export type UpdateRecipeImageMutationResult =
  Apollo.MutationResult<UpdateRecipeImageMutation>;
export type UpdateRecipeImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecipeImageMutation,
  UpdateRecipeImageMutationVariables
>;
export const UpdateRecipeDocument = gql`
  mutation UpdateRecipe($id: Int!, $input: UpdateRecipeInputType!) {
    updateRecipe(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<
  UpdateRecipeMutation,
  UpdateRecipeMutationVariables
>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecipeMutation,
    UpdateRecipeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(
    UpdateRecipeDocument,
    options,
  );
}
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecipeMutation,
  UpdateRecipeMutationVariables
>;
export const GetApiKeysDocument = gql`
  query GetApiKeys($page: Int = 1, $limit: Int = 10) {
    apiKeys(pagination: { page: $page, limit: $limit }) {
      data {
        id
        name
        key
      }
    }
  }
`;

/**
 * __useGetApiKeysQuery__
 *
 * To run a query within a React component, call `useGetApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeysQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetApiKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetApiKeysQuery, GetApiKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options,
  );
}
export function useGetApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApiKeysQuery, GetApiKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options,
  );
}
export type GetApiKeysQueryHookResult = ReturnType<typeof useGetApiKeysQuery>;
export type GetApiKeysLazyQueryHookResult = ReturnType<typeof useGetApiKeysLazyQuery>;
export type GetApiKeysQueryResult = Apollo.QueryResult<
  GetApiKeysQuery,
  GetApiKeysQueryVariables
>;
export const CreateApiKeyDocument = gql`
  mutation CreateApiKey($input: CreateApiKeyInputType!) {
    createApiKey(input: $input) {
      id
    }
  }
`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(
    CreateApiKeyDocument,
    options,
  );
}
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const AddExerciseToWorkoutDayDocument = gql`
  mutation AddExerciseToWorkoutDay($id: Int!, $input: AddExerciseToWorkoutDayInputType!) {
    addExerciseToWorkoutDay(workoutDayId: $id, input: $input) {
      id
    }
  }
`;
export type AddExerciseToWorkoutDayMutationFn = Apollo.MutationFunction<
  AddExerciseToWorkoutDayMutation,
  AddExerciseToWorkoutDayMutationVariables
>;

/**
 * __useAddExerciseToWorkoutDayMutation__
 *
 * To run a mutation, you first call `useAddExerciseToWorkoutDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExerciseToWorkoutDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExerciseToWorkoutDayMutation, { data, loading, error }] = useAddExerciseToWorkoutDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddExerciseToWorkoutDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddExerciseToWorkoutDayMutation,
    AddExerciseToWorkoutDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddExerciseToWorkoutDayMutation,
    AddExerciseToWorkoutDayMutationVariables
  >(AddExerciseToWorkoutDayDocument, options);
}
export type AddExerciseToWorkoutDayMutationHookResult = ReturnType<
  typeof useAddExerciseToWorkoutDayMutation
>;
export type AddExerciseToWorkoutDayMutationResult =
  Apollo.MutationResult<AddExerciseToWorkoutDayMutation>;
export type AddExerciseToWorkoutDayMutationOptions = Apollo.BaseMutationOptions<
  AddExerciseToWorkoutDayMutation,
  AddExerciseToWorkoutDayMutationVariables
>;
export const MoveWorkoutDocument = gql`
  mutation MoveWorkout($input: MoveWorkoutInputType!) {
    moveWorkout(input: $input) {
      id
    }
  }
`;
export type MoveWorkoutMutationFn = Apollo.MutationFunction<
  MoveWorkoutMutation,
  MoveWorkoutMutationVariables
>;

/**
 * __useMoveWorkoutMutation__
 *
 * To run a mutation, you first call `useMoveWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveWorkoutMutation, { data, loading, error }] = useMoveWorkoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveWorkoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveWorkoutMutation,
    MoveWorkoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveWorkoutMutation, MoveWorkoutMutationVariables>(
    MoveWorkoutDocument,
    options,
  );
}
export type MoveWorkoutMutationHookResult = ReturnType<typeof useMoveWorkoutMutation>;
export type MoveWorkoutMutationResult = Apollo.MutationResult<MoveWorkoutMutation>;
export type MoveWorkoutMutationOptions = Apollo.BaseMutationOptions<
  MoveWorkoutMutation,
  MoveWorkoutMutationVariables
>;
export const DeleteWorkoutDocument = gql`
  mutation DeleteWorkout($id: Int!) {
    deleteWorkout(id: $id)
  }
`;
export type DeleteWorkoutMutationFn = Apollo.MutationFunction<
  DeleteWorkoutMutation,
  DeleteWorkoutMutationVariables
>;

/**
 * __useDeleteWorkoutMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutMutation, { data, loading, error }] = useDeleteWorkoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkoutMutation,
    DeleteWorkoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>(
    DeleteWorkoutDocument,
    options,
  );
}
export type DeleteWorkoutMutationHookResult = ReturnType<typeof useDeleteWorkoutMutation>;
export type DeleteWorkoutMutationResult = Apollo.MutationResult<DeleteWorkoutMutation>;
export type DeleteWorkoutMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkoutMutation,
  DeleteWorkoutMutationVariables
>;
export const DeleteApiKeyDocument = gql`
  mutation DeleteApiKey($id: Int!) {
    deleteApiKey(id: $id)
  }
`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiKeyMutation,
    DeleteApiKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(
    DeleteApiKeyDocument,
    options,
  );
}
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;
export const UpdateRecipeIngredientDocument = gql`
  mutation UpdateRecipeIngredient($id: Int!, $input: UpdateRecipeIngredientInputType!) {
    updateRecipeIngredient(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateRecipeIngredientMutationFn = Apollo.MutationFunction<
  UpdateRecipeIngredientMutation,
  UpdateRecipeIngredientMutationVariables
>;

/**
 * __useUpdateRecipeIngredientMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeIngredientMutation, { data, loading, error }] = useUpdateRecipeIngredientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecipeIngredientMutation,
    UpdateRecipeIngredientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecipeIngredientMutation,
    UpdateRecipeIngredientMutationVariables
  >(UpdateRecipeIngredientDocument, options);
}
export type UpdateRecipeIngredientMutationHookResult = ReturnType<
  typeof useUpdateRecipeIngredientMutation
>;
export type UpdateRecipeIngredientMutationResult =
  Apollo.MutationResult<UpdateRecipeIngredientMutation>;
export type UpdateRecipeIngredientMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecipeIngredientMutation,
  UpdateRecipeIngredientMutationVariables
>;
export const ReorderWorkoutDocument = gql`
  mutation ReorderWorkout($workoutDayId: Int!, $input: ReorderWorkoutInputType!) {
    reorderWorkout(workoutDayId: $workoutDayId, input: $input) {
      id
    }
  }
`;
export type ReorderWorkoutMutationFn = Apollo.MutationFunction<
  ReorderWorkoutMutation,
  ReorderWorkoutMutationVariables
>;

/**
 * __useReorderWorkoutMutation__
 *
 * To run a mutation, you first call `useReorderWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderWorkoutMutation, { data, loading, error }] = useReorderWorkoutMutation({
 *   variables: {
 *      workoutDayId: // value for 'workoutDayId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderWorkoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderWorkoutMutation,
    ReorderWorkoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReorderWorkoutMutation, ReorderWorkoutMutationVariables>(
    ReorderWorkoutDocument,
    options,
  );
}
export type ReorderWorkoutMutationHookResult = ReturnType<
  typeof useReorderWorkoutMutation
>;
export type ReorderWorkoutMutationResult = Apollo.MutationResult<ReorderWorkoutMutation>;
export type ReorderWorkoutMutationOptions = Apollo.BaseMutationOptions<
  ReorderWorkoutMutation,
  ReorderWorkoutMutationVariables
>;
export const UpdateWorkoutDocument = gql`
  mutation UpdateWorkout($id: Int!, $input: UpdateWorkoutInputType!) {
    updateWorkout(id: $id, input: $input) {
      id
      reps
      sets
      rest
      time
      description
    }
  }
`;
export type UpdateWorkoutMutationFn = Apollo.MutationFunction<
  UpdateWorkoutMutation,
  UpdateWorkoutMutationVariables
>;

/**
 * __useUpdateWorkoutMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutMutation, { data, loading, error }] = useUpdateWorkoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkoutMutation,
    UpdateWorkoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>(
    UpdateWorkoutDocument,
    options,
  );
}
export type UpdateWorkoutMutationHookResult = ReturnType<typeof useUpdateWorkoutMutation>;
export type UpdateWorkoutMutationResult = Apollo.MutationResult<UpdateWorkoutMutation>;
export type UpdateWorkoutMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkoutMutation,
  UpdateWorkoutMutationVariables
>;
export const CreateWorkoutDayDocument = gql`
  mutation CreateWorkoutDay($id: Int!, $input: WorkoutDayInputType!) {
    createWorkoutDay(workoutPlanId: $id, input: $input) {
      id
      name
      exercises {
        ...WorkoutSelection
      }
    }
  }
  ${WorkoutSelectionFragmentDoc}
`;
export type CreateWorkoutDayMutationFn = Apollo.MutationFunction<
  CreateWorkoutDayMutation,
  CreateWorkoutDayMutationVariables
>;

/**
 * __useCreateWorkoutDayMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutDayMutation, { data, loading, error }] = useCreateWorkoutDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkoutDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkoutDayMutation,
    CreateWorkoutDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWorkoutDayMutation, CreateWorkoutDayMutationVariables>(
    CreateWorkoutDayDocument,
    options,
  );
}
export type CreateWorkoutDayMutationHookResult = ReturnType<
  typeof useCreateWorkoutDayMutation
>;
export type CreateWorkoutDayMutationResult =
  Apollo.MutationResult<CreateWorkoutDayMutation>;
export type CreateWorkoutDayMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkoutDayMutation,
  CreateWorkoutDayMutationVariables
>;
export const DeleteWorkoutDayDocument = gql`
  mutation DeleteWorkoutDay($id: Int!) {
    deleteWorkoutDay(id: $id)
  }
`;
export type DeleteWorkoutDayMutationFn = Apollo.MutationFunction<
  DeleteWorkoutDayMutation,
  DeleteWorkoutDayMutationVariables
>;

/**
 * __useDeleteWorkoutDayMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutDayMutation, { data, loading, error }] = useDeleteWorkoutDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkoutDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkoutDayMutation,
    DeleteWorkoutDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWorkoutDayMutation, DeleteWorkoutDayMutationVariables>(
    DeleteWorkoutDayDocument,
    options,
  );
}
export type DeleteWorkoutDayMutationHookResult = ReturnType<
  typeof useDeleteWorkoutDayMutation
>;
export type DeleteWorkoutDayMutationResult =
  Apollo.MutationResult<DeleteWorkoutDayMutation>;
export type DeleteWorkoutDayMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkoutDayMutation,
  DeleteWorkoutDayMutationVariables
>;
export const DuplicateWorkoutDayDocument = gql`
  mutation DuplicateWorkoutDay($id: Int!, $input: WorkoutDayInputType!) {
    duplicateWorkoutDay(id: $id, input: $input) {
      id
      name
      exercises {
        ...WorkoutSelection
      }
    }
  }
  ${WorkoutSelectionFragmentDoc}
`;
export type DuplicateWorkoutDayMutationFn = Apollo.MutationFunction<
  DuplicateWorkoutDayMutation,
  DuplicateWorkoutDayMutationVariables
>;

/**
 * __useDuplicateWorkoutDayMutation__
 *
 * To run a mutation, you first call `useDuplicateWorkoutDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateWorkoutDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateWorkoutDayMutation, { data, loading, error }] = useDuplicateWorkoutDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateWorkoutDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateWorkoutDayMutation,
    DuplicateWorkoutDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateWorkoutDayMutation,
    DuplicateWorkoutDayMutationVariables
  >(DuplicateWorkoutDayDocument, options);
}
export type DuplicateWorkoutDayMutationHookResult = ReturnType<
  typeof useDuplicateWorkoutDayMutation
>;
export type DuplicateWorkoutDayMutationResult =
  Apollo.MutationResult<DuplicateWorkoutDayMutation>;
export type DuplicateWorkoutDayMutationOptions = Apollo.BaseMutationOptions<
  DuplicateWorkoutDayMutation,
  DuplicateWorkoutDayMutationVariables
>;
export const UpdateWorkoutDayDocument = gql`
  mutation UpdateWorkoutDay($id: Int!, $input: UpdateWorkoutDayInputType!) {
    updateWorkoutDay(id: $id, input: $input) {
      id
      name
    }
  }
`;
export type UpdateWorkoutDayMutationFn = Apollo.MutationFunction<
  UpdateWorkoutDayMutation,
  UpdateWorkoutDayMutationVariables
>;

/**
 * __useUpdateWorkoutDayMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutDayMutation, { data, loading, error }] = useUpdateWorkoutDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkoutDayMutation,
    UpdateWorkoutDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkoutDayMutation, UpdateWorkoutDayMutationVariables>(
    UpdateWorkoutDayDocument,
    options,
  );
}
export type UpdateWorkoutDayMutationHookResult = ReturnType<
  typeof useUpdateWorkoutDayMutation
>;
export type UpdateWorkoutDayMutationResult =
  Apollo.MutationResult<UpdateWorkoutDayMutation>;
export type UpdateWorkoutDayMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkoutDayMutation,
  UpdateWorkoutDayMutationVariables
>;
export const ActivateClientDocument = gql`
  mutation ActivateClient($id: Int!) {
    activateClient(clientId: $id) {
      id
    }
  }
`;
export type ActivateClientMutationFn = Apollo.MutationFunction<
  ActivateClientMutation,
  ActivateClientMutationVariables
>;

/**
 * __useActivateClientMutation__
 *
 * To run a mutation, you first call `useActivateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateClientMutation, { data, loading, error }] = useActivateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateClientMutation,
    ActivateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateClientMutation, ActivateClientMutationVariables>(
    ActivateClientDocument,
    options,
  );
}
export type ActivateClientMutationHookResult = ReturnType<
  typeof useActivateClientMutation
>;
export type ActivateClientMutationResult = Apollo.MutationResult<ActivateClientMutation>;
export type ActivateClientMutationOptions = Apollo.BaseMutationOptions<
  ActivateClientMutation,
  ActivateClientMutationVariables
>;
export const GetClientDietPreferenceDocument = gql`
  query GetClientDietPreference($id: Int!) {
    client(id: $id) {
      id
      name
      photo
      locale
      startWeight
      weightGoal
      measuringSystem
      birthday
      age
      gender
      height
      pal
      numberOfMeals
      clientFoodPreferences {
        isPescetarian
        isVegan
        isVegetarian
        lactose
        pig
        lactose
        shellfish
        gluten
        nuts
        eggs
        fish
      }
    }
  }
`;

/**
 * __useGetClientDietPreferenceQuery__
 *
 * To run a query within a React component, call `useGetClientDietPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDietPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDietPreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientDietPreferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientDietPreferenceQuery,
    GetClientDietPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientDietPreferenceQuery,
    GetClientDietPreferenceQueryVariables
  >(GetClientDietPreferenceDocument, options);
}
export function useGetClientDietPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientDietPreferenceQuery,
    GetClientDietPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientDietPreferenceQuery,
    GetClientDietPreferenceQueryVariables
  >(GetClientDietPreferenceDocument, options);
}
export type GetClientDietPreferenceQueryHookResult = ReturnType<
  typeof useGetClientDietPreferenceQuery
>;
export type GetClientDietPreferenceLazyQueryHookResult = ReturnType<
  typeof useGetClientDietPreferenceLazyQuery
>;
export type GetClientDietPreferenceQueryResult = Apollo.QueryResult<
  GetClientDietPreferenceQuery,
  GetClientDietPreferenceQueryVariables
>;
