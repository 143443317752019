import { Button, Stack, Typography, useTheme } from "@mui/material";
import { UilUsdCircle } from "@iconscout/react-unicons";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { TranslatorNS } from "../../../i18n/const";
import { ModalPayload, ModalTypeEnum } from "../../../store/modals/reducerTypes";
import { openGlobalModalAction } from "../../../store/modals/action";
import Iconify from "../../../components/_MUI/Iconify";

type type = "missingStripe" | "missingSubscription";

interface Props {
  type: type;
  stripeConnectUrl?: string | undefined;
  handlePaymentModal?: () => void;
  openGlobalModalAction: (
    payload: ModalPayload<ModalTypeEnum.ClientSubscription>,
  ) => void;
}

function SubscriptionEmpty(props: Props) {
  const { type, stripeConnectUrl, handlePaymentModal, openGlobalModalAction } = props;
  const { clientId } = useParams<{ clientId: string }>();

  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  const missingStripeConnect = type === "missingStripe";

  const title = missingStripeConnect
    ? "newClientOverview.automatePayment"
    : "newClientOverview.missingSub";

  const buttonString = missingStripeConnect
    ? "newClientOverview.connectStripe"
    : "newClientOverview.setupSub";

  return (
    <Stack
      mt={missingStripeConnect ? 0.5 : 1}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}>
      <Stack flexDirection={"row"} alignItems={"center"}>
        <Stack>
          <UilUsdCircle color={palette.success.darker} size={22} />
        </Stack>
        <Stack ml={2}>
          <Typography variant={"subtitle1"}>{t(title)}</Typography>
          {missingStripeConnect && (
            <Typography sx={{ color: palette.grey[600] }}>
              {t("newClientOverview.integrate")}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack>
        <Button
          variant={"contained"}
          size={"medium"}
          onClick={() =>
            type === "missingStripe"
              ? window.open(stripeConnectUrl, "_self")
              : openGlobalModalAction({
                  type: ModalTypeEnum.ClientSubscription,
                  context: {
                    clientId: Number(clientId),
                  },
                })
          }
          disabled
          startIcon={<Iconify icon="uil:lock-alt" />}>
          {t(buttonString)}
        </Button>
      </Stack>
    </Stack>
  );
}

export default connect(undefined, { openGlobalModalAction })(SubscriptionEmpty);
